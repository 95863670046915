import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { Trans, useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import ProjectServices from "../../../services/ProjectServices";
import ItemActions from "../../_common/ItemActions";
import NewAction from "../../_common/NewAction";

export default function ProjectList() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({
    pageIndex = 1,
    pageSize = 50,
    sortBy,
    globalFilter,
    filters,
  } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const mappedFilters = filters.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await ProjectServices.index({
      page: pageIndex + 1,
      pageSize,
      sortBy,
      globalFilter,
      ...mappedFilters,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Project Name"),
      accessor: "name",
      align: "left",
      /* eslint-disable react/no-unstable-nested-components, react/prop-types */
      Cell: ({
        value,
        row: {
          original: { id },
        },
        /* eslint-enable react/prop-types, react/no-unstable-nested-components, react/prop-types */
      }) => (
        <Link component={RouterLink} to={`/projects/${id}/drawings`} color="primary">
          {value}
        </Link>
      ),
    },
    {
      Header: t("Client Name"),
      accessor: ({ employee }) => {
        const { client } = employee || {};
        const { name } = client || {};
        return name;
      },
      disableSortBy: true,
      align: "left",
    },
    {
      Header: t("Project Person In Charge"),
      // eslint-disable-next-line camelcase
      accessor: ({ assigned_to }) => {
        // eslint-disable-next-line camelcase
        const { name } = assigned_to || {};
        return name;
      },
      disableSortBy: true,
      align: "left",
    },
    {
      Header: t("Status"),
      // eslint-disable-next-line camelcase
      accessor: "is_completed",
      align: "center",
      // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
      Filter: ({ column: { setFilter, filterValue = -1 } }) => (
        <FormControl fullWidth>
          <Select
            id="demo-simple-select"
            value={filterValue}
            onChange={({ target: { value } }) => {
              setFilter(value);
            }}
          >
            <MenuItem value={2}>{t("All")}</MenuItem>
            <MenuItem value={1}>{t("Completed")}</MenuItem>
            <MenuItem value={3}>{t("Uncompleted")}</MenuItem>
          </Select>
        </FormControl>
      ),
      Cell: ({ value }) => (value ? t("Completed") : t("Uncompleted")),
    },
    {
      Header: t("Project Ordered At"),
      id: "ordered_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ ordered_at }) => format(parseISO(ordered_at), "yyy-MM-dd"),
      align: "left",
    },
    {
      Header: t("Project Constructed At"),
      id: "constructed_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ constructed_at }) => format(parseISO(constructed_at), "yyy-MM-dd"),
      align: "left",
    },
    {
      Header: t("Created By"),
      // eslint-disable-next-line camelcase
      accessor: ({ created_by }) => {
        // eslint-disable-next-line camelcase
        const { name } = created_by || {};
        return name;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { creator_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/users/${creator_id}/edit`}>
          {value}
        </Link>
      ),
      align: "left",
      disableSortBy: true,
    },
    {
      Header: t("Created At"),
      id: "created_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ created_at }) => format(parseISO(created_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Updated At"),
      id: "updated_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ updated_at }) => format(parseISO(updated_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Action"),
      disableSortBy: true,
      accessor: "action",
      align: "center",
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({
        value,
        state: { pageIndex, pageSize, sortBy, filters, globalFilter },
        row: { original },
      }) => {
        const { employee = {}, name } = original;
        /* eslint-enable react/no-unstable-nested-components,react/prop-types */
        const { client = {} } = employee || {};
        const { name: clientName } = client || {};
        return (
          <ItemActions
            editLink={`/projects/${value}/edit`}
            key={value}
            object="Project"
            question={
              <MDBox>
                <MDTypography variant="h6">
                  <Trans i18nKey="destroy_confirmation">
                    Are you sure to delete {{ object: t("Project") }}
                  </Trans>
                </MDTypography>
                <div>
                  {t("Project Name")} {name}
                </div>
                <div>
                  {t("Client Name")} {clientName}
                </div>
              </MDBox>
            }
            onDelete={async () => {
              await ProjectServices.destroy({ id: value });
              await handleFetchData({ pageIndex, pageSize, sortBy, filters, globalFilter });
            }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Projects Index")}
              </MDTypography>
              <NewAction object="Project" />
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                manualFilters
                manualGlobalFilter
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
                canSearch
                initialFilters={[{ id: "is_completed", value: 2 }]}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
