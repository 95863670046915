import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import DrawingServices from "../../../services/DrawingServices";
import { processError } from "../../../utils/services/api";
import useFetch from "../../../utils/hooks/useFetch";
import UserServices from "../../../services/UserServices";
import StepTemplateServices from "../../../services/StepTemplateServices";

/* eslint-disable camelcase, react/prop-types */
export default function BulkCreate({ prefix = "" }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const handleCreate = async (values, { setSubmitting }) => {
    try {
      const payload = new FormData();
      if (values.assigned_to_id) {
        payload.append(`drawing[assigned_to_id]`, values.assigned_to_id);
      }
      ["prefix", "start_from", "amount"].forEach((col) => {
        if (values[col]) {
          payload.append(col, values[col]);
        }
      });
      values.setting_ids.forEach((id) => {
        payload.append("setting_ids[]", id);
      });
      ["memo", "is_additional"].forEach((col) => {
        if (values[col] !== undefined) {
          payload.append(`revision[${col}]`, values[col]);
        }
      });
      payload.append(`revision[rev_number]`, "rev0");
      await DrawingServices.bulkCreate({
        projectId,
        payload,
      });
      navigate(`${prefix}/projects/${projectId}/drawings`);
      toast.success(t("Succeeded"));
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object({
    prefix: yup.string().max(30).required().label(t("Drawing Name")),
    amount: yup.number().integer().min(1).required().label(t("Bulk Amount")),
    start_from: yup.number().integer().min(1).required().label(t("Bulk From")),
    is_additional: yup.mixed().required().label(t("Order Type")),
    memo: yup.string(),
    setting_ids: yup.array().of(yup.number().positive()),
    assigned_to_id: yup.number().positive().required().label(t("Drawing Assigned To")),
  });
  const [users] = useFetch({
    initialState: [],
    fetchFn: async () => UserServices.index(),
  });
  const [processingSteps] = useFetch({
    initialState: [],
    fetchFn: async () => StepTemplateServices.index({ type_name: "treatment" }),
  });
  const [factoringSteps] = useFetch({
    initialState: [],
    fetchFn: async () => StepTemplateServices.index({ type_name: "factory_inspection" }),
  });
  const [siteSteps] = useFetch({
    initialState: [],
    fetchFn: async () => StepTemplateServices.index({ type_name: "site_inspection" }),
  });
  return (
    <MDBox pt={6} pb={3}>
      <Card px={6}>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
        >
          <MDTypography variant="h6" color="white" mt={1}>
            {t("Bulk Create Drawings")}
          </MDTypography>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h6" color="white" mt={1}>
            {t("Basic Info")}
          </MDTypography>
          <Formik
            enableReinitialize
            initialValues={{
              prefix: "",
              setting_ids: [],
              start_from: "",
              amount: "",
              memo: "",
              is_additional: 0,
              assigned_to_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleCreate}
          >
            {({
              values: { setting_ids },
              isValid,
              setFieldValue,
              submitForm,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <MDBox p={2}>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <MDBox mb={2}>
                            <Field
                              component={TextField}
                              name="prefix"
                              type="text"
                              label={t("Drawing Name")}
                              variant="standard"
                              fullWidth
                              required
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={6}>
                          <MDBox mb={2}>
                            <Field
                              component={TextField}
                              name="amount"
                              type="number"
                              label={t("Bulk Amount")}
                              variant="standard"
                              required
                              fullWidth
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={6}>
                          <MDBox mb={2}>
                            <Field
                              component={TextField}
                              name="start_from"
                              type="number"
                              label={t("Bulk From")}
                              variant="standard"
                              required
                              fullWidth
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={6}>
                          <MDBox mb={2}>
                            <FormControl fullWidth>
                              <Field
                                component={TextField}
                                id="is_additional"
                                name="is_additional"
                                select
                                variant="standard"
                                label={t("Order Type")}
                                required
                              >
                                <MenuItem value={0}>{t("Normal Order")}</MenuItem>
                                <MenuItem value={1}>{t("Additional Order")}</MenuItem>
                              </Field>
                            </FormControl>
                          </MDBox>
                        </Grid>
                        <Grid item md={6}>
                          <MDBox mb={2}>
                            <FormControl fullWidth>
                              <Field
                                component={TextField}
                                id="assigned_to_id"
                                name="assigned_to_id"
                                select
                                variant="standard"
                                label={t("Drawing Assigned To")}
                                required
                              >
                                {users.map(({ name, id: userId }) => (
                                  <MenuItem value={userId} key={userId}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item md={6}>
                    <MDBox p={2}>
                      <Field
                        component={TextField}
                        name="memo"
                        multiline
                        minRows={5}
                        maxRows={10}
                        type="text"
                        label={t("Memo")}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox p={2}>
                  <MDTypography variant="h6">{t("Processing Settings")}</MDTypography>
                  {processingSteps.map(({ id, ...step }) => (
                    <MDButton
                      variant={
                        Array.isArray(setting_ids) && setting_ids.includes(id)
                          ? "contained"
                          : "outlined"
                      }
                      key={id}
                      color="primary"
                      sx={{ minWidth: "100px", mr: 2, mt: 2 }}
                      onClick={() => {
                        if (!setting_ids.includes(id)) {
                          setFieldValue("setting_ids", [...setting_ids, id]);
                        } else {
                          const result = setting_ids.filter((v) => v !== id);
                          setFieldValue("setting_ids", result);
                        }
                      }}
                    >
                      {step[`title_${i18n.language}`] || step.title_en}
                    </MDButton>
                  ))}
                </MDBox>
                <MDBox p={2}>
                  <MDTypography variant="h6">{t("Inspection Settings")}</MDTypography>
                  {factoringSteps.map(({ id, name, ...step }) => (
                    <MDButton
                      variant={
                        Array.isArray(setting_ids) && setting_ids.includes(id)
                          ? "contained"
                          : "outlined"
                      }
                      key={id}
                      color="primary"
                      sx={{ minWidth: "100px", mr: 2, mt: 2 }}
                      onClick={() => {
                        const siteStep = siteSteps.find(
                          ({ name: siteStepName }) => siteStepName === name
                        );
                        if (!setting_ids.includes(id)) {
                          if (siteStep !== undefined) {
                            setFieldValue("setting_ids", [...setting_ids, id, siteStep.id]);
                          } else {
                            setFieldValue("setting_ids", [...setting_ids, id]);
                          }
                        } else {
                          const result = setting_ids.filter(
                            (v) => !(v === id || v === siteStep?.id)
                          );
                          setFieldValue("setting_ids", result);
                        }
                      }}
                    >
                      {step[`title_${i18n.language}`] || step.title_en}
                    </MDButton>
                  ))}
                </MDBox>
                <MDBox mt={4} mb={1} sx={{ justifyContent: "space-between", display: "flex" }}>
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={resetForm}
                  >
                    {t("Reset")}
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="info"
                    startIcon={
                      isSubmitting ? <CircularProgress size={20} color="white" /> : <SaveIcon />
                    }
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                  >
                    {t("Save")}
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </MDBox>
  );
}
