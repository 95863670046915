import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

function DeleteConfirmationDlg({ onClose, onOk, open, question, ...other }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} {...other}>
      <DialogTitle>
        {t("Deletion confirm")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{question || t("Are you sure?")}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button onClick={onOk}>{t("Delete Button")}</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteConfirmationDlg.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DeleteConfirmationDlg.defaultProps = {
  question: null,
};

export default DeleteConfirmationDlg;
