import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMemo, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTable } from "react-table";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { CircularProgress } from "@mui/material";
import MDBox from "../../../components/MDBox";
import PieceServices from "../../../services/PieceServices";
import DoneFlag from "../../../components/DoneFlag";
import StageLog from "./StageLog";
import useFetch from "../../../utils/hooks/useFetch";
import DataTableHeadCell from "../../../examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "../../../examples/Tables/DataTable/DataTableBodyCell";

/* eslint-disable react/no-unstable-nested-components, react/prop-types, camelcase */
function RevisionModal({ onClose, drawingId, open }) {
  const { t } = useTranslation();
  const [pieces] = useFetch({
    fetchFn: async () =>
      PieceServices.index({
        pageSize: -1,
        drawingId,
        with: ["progresses.stage.type"],
      }),
    deps: [drawingId],
    initialState: [],
  });
  const columns = useMemo(
    () => [
      {
        Header: t("Piece Name"),
        accessor: "name",
        align: "left",
      },
      {
        Header: t("Manufacturing"),
        id: "production_progress",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "production_progress"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("FactoryInspection"),
        id: "factory_inspection",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "factory_inspection"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("Treatment"),
        id: "treatment",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(({ stage: { type: { name } = {} } = {} }) => name === "treatment"),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("shipping_registration"),
        id: "shipping_registration",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "shipping_registration"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("ArrivalCheck"),
        id: "arrival_check",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "arrival_check"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("SiteInspection"),
        id: "site_inspection",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "site_inspection"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
      {
        Header: t("Construction"),
        id: "construction_progress",
        disableSortBy: true,
        accessor: ({ piece_progresses }) =>
          piece_progresses.find(
            ({ stage: { type: { name } = {} } = {} }) => name === "construction_progress"
          ),
        Cell: ({ value }) =>
          value ? (
            <StageLog progress={value} type={value?.stage?.type}>
              {({ onClick }) => <DoneFlag value={value?.is_final} onClick={onClick} />}
            </StageLog>
          ) : null,
      },
    ],
    []
  );
  const data = useMemo(() => pieces, [JSON.stringify(columns), JSON.stringify(pieces)]);
  const tableInstance = useTable({
    columns,
    data,
    autoResetPage: false,
  });
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = tableInstance;

  const RenderRow = useCallback(
    () =>
      rows.map((row) => {
        prepareRow(row);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <DataTableBodyCell
                align={cell.column.align ? cell.column.align : "left"}
                {...cell.getCellProps()}
              >
                {cell.render("Cell")}
              </DataTableBodyCell>
            ))}
          </TableRow>
        );
      }),
    [prepareRow, rows]
  );
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t("Progression Detail")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <MDBox p={3}>
          <TableContainer sx={{ boxShadow: "none" }}>
            <Table {...getTableProps()}>
              <MDBox component="thead">
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <DataTableHeadCell
                        {...column.getHeaderProps()}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        sorted={false}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                ))}
              </MDBox>
              <TableBody {...getTableBodyProps()}>
                {rows.length === 0 ? (
                  <TableRow>
                    <DataTableBodyCell>
                      <CircularProgress />
                    </DataTableBodyCell>
                  </TableRow>
                ) : (
                  <RenderRow />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}

RevisionModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawingId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RevisionModal;
