/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
// react-table components
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { Trans, useTranslation } from "react-i18next";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { CircularProgress } from "@mui/material";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  pageCount,
  manualPagination,
  manualGlobalFilter,
  manualFilters,
  loading,
  isSorted,
  noEndBorder,
  onFetchData,
  skipPageReset,
  initialFilters,
  initialSorts = [{ id: "created_at", desc: true }],
  total = table.rows.length,
  incr,
  extra,
}) {
  const { t } = useTranslation();
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50", "100"];
  const { columns } = table;
  const data = useMemo(
    () => table.rows,
    [JSON.stringify(table.columns), JSON.stringify(table.rows), incr]
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: initialSorts,
        filters: initialFilters,
      },
      manualPagination,
      manualGlobalFilter,
      manualFilters,
      pageCount,
      autoResetPage: !skipPageReset,
      // autoResetExpanded: !skipPageReset,
      // autoResetGroupBy: !skipPageReset,
      // autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetGlobalFilter: !skipPageReset,
      // autoResetRowState: !skipPageReset,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy, filters },
  } = tableInstance;
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);

  useEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters, globalFilter });
  }, [
    onFetchDataDebounced,
    pageIndex,
    pageSize,
    JSON.stringify(sortBy),
    JSON.stringify(filters),
    globalFilter,
    incr,
  ]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the pagination
  const startPage = Math.max(0, pageIndex - 2);
  const endPage = Math.min(pageCount, pageIndex + 2);
  const renderPagination = pageOptions.slice(startPage, endPage + 1).map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = ({ isSorted: isColumnSorted, isSortedDesc, canSort }) => {
    let sortedValue;

    if (!canSort) return false;
    if (isSorted && isColumnSorted) {
      sortedValue = isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = rows.length > pageSize ? pageSize : rows.length;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary" ml={2}>
                {t("entries per page")}
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("Search...")}
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <TableContainer sx={{ boxShadow: "none" }}>
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                    {column.canFilter && column.Filter ? (
                      <MDBox>{column.render("Filter")}</MDBox>
                    ) : null}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {loading ? (
              <TableRow>
                <DataTableBodyCell>
                  <CircularProgress />
                </DataTableBodyCell>
              </TableRow>
            ) : (
              page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <DataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell", { incr, extra })}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 2}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                <Trans i18nKey="pagination_info">
                  Showing {{ entriesStart }} to {{ entriesEnd }} of {{ length: total }} entries
                </Trans>
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
              {canPreviousPage && pageIndex > 2 && (
                <MDPagination item onClick={() => gotoPage(0)}>
                  <Icon sx={{ fontWeight: "bold" }}>first_page</Icon>
                </MDPagination>
              )}
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination}
              {canNextPage && pageIndex < pageCount - 3 && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
              {canNextPage && pageCount > 3 && pageIndex < pageCount - 4 && (
                <MDPagination item onClick={() => gotoPage(pageCount - 1)}>
                  <Icon sx={{ fontWeight: "bold" }}>last_page</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 50, entries: [10, 25, 50, 100] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  manualPagination: false,
  manualFilters: false,
  manualGlobalFilter: false,
  pageCount: -1,
  loading: false,
  skipPageReset: true,
  onFetchData: () => {},
  initialFilters: [],
  incr: 0,
  extra: {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.arrayOf).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  pageCount: PropTypes.number,
  manualPagination: PropTypes.bool,
  manualFilters: PropTypes.bool,
  manualGlobalFilter: PropTypes.bool,
  skipPageReset: PropTypes.bool,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.bool,
  onFetchData: PropTypes.func,
  initialFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
    })
  ),
  // eslint-disable-next-line react/require-default-props
  initialSorts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
    })
  ),
  // eslint-disable-next-line react/require-default-props
  total: PropTypes.number,
  incr: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  extra: PropTypes.object,
};

export default DataTable;
