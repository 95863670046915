/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSanctum } from "react-sanctum";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import "react-toastify/dist/ReactToastify.css";
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
// Images
import brandWhite from "assets/images/matsusho.png";
import SignIn from "./layouts/authentication/sign-in";
import ForgotPassword from "./layouts/authentication/forgot-password";
import ResetPassword from "./layouts/authentication/reset-password";
import { isAllowed, matchRoute } from "./utils/config";
import adminRoutes from "./adminRoutes";
import businessRoutes from "./businessRoutes";
import projectManagerRoutes from "./projectManagerRoutes";
import viewerRoutes from "./viewerRoutes";
import RequiredAuth from "./components/RequireAuth";
import EmptyLayout from "./components/EmptyLayout";
import SignInTemplate from "./components/SignInTemplate";
import MyOutlet from "./components/MyOutlet";
import UserLayout from "./components/UserLayout";

const routesMap = {
  admin: {
    prefix: "/admin/",
    routes: adminRoutes,
    home: "/admin/users/",
    allowances: ["super_admin"],
  },
  viewer: {
    prefix: "/viewer/",
    routes: viewerRoutes,
    home: "/viewer/users/",
    allowances: [
      "super_admin",
      "project_manager",
      "operator",
      "user",
      "business",
      "supervisor",
      "in_house_producer",
      "shipper",
      "acceptance",
      "installer",
    ],
  },
  business: {
    prefix: "/operator/",
    routes: businessRoutes,
    home: "/operator/projects/",
    allowances: [
      "super_admin",
      "business",
      "supervisor",
      "in_house_producer",
      "shipper",
      "acceptance",
      "installer",
    ],
  },
  pm: {
    prefix: "/project_manager/",
    routes: businessRoutes,
    home: "/project_manager/projects/",
    allowances: ["super_admin", "project_manager"],
  },
  user: {
    prefix: "",
    routes,
    home: "/projects/",
    allowances: ["super_admin", "operator", "user"],
  },
};
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    // direction,
    layout,
    openConfigurator,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  //
  // // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const { authenticated, user } = useSanctum();
  const getRoutes = ({ allRoutes, _user, auth, allowances = [] }) => {
    if (_user?.roles?.find(({ name }) => allowances.includes(name)) === undefined) {
      return [];
    }
    return allRoutes
      .filter(({ object, operator }) =>
        isAllowed({ object, operator, user: _user, authenticated: auth })
      )
      .map(({ collapse, route, exact = true, component, key }) => {
        if (collapse) {
          return getRoutes(collapse);
        }

        if (route) {
          return (
            <Route
              exact={exact}
              path={route}
              element={<RequiredAuth>{component}</RequiredAuth>}
              key={key}
            />
          );
        }

        return null;
      });
  };
  const matchedBranch = matchRoute(pathname, routesMap);
  const renderRoutes = useMemo(
    () =>
      getRoutes({
        allRoutes: routes,
        _user: user,
        auth: authenticated,
        allowances: matchedBranch.allowances,
      }),
    [JSON.stringify(user), authenticated]
  );
  const renderAdminRoutes = useMemo(
    () =>
      getRoutes({
        allRoutes: adminRoutes,
        _user: user,
        auth: authenticated,
        allowances: matchedBranch.allowances,
      }),
    [JSON.stringify(user), authenticated]
  );
  const renderViewerRoutes = useMemo(
    () =>
      getRoutes({
        allRoutes: viewerRoutes,
        _user: user,
        auth: authenticated,
        allowances: matchedBranch.allowances,
      }),
    [JSON.stringify(user), authenticated]
  );
  const renderBusinessRoutes = useMemo(
    () =>
      getRoutes({
        allRoutes: businessRoutes,
        _user: user,
        auth: authenticated,
        allowances: matchedBranch.allowances,
      }),
    [JSON.stringify(user), authenticated]
  );
  const renderPmRoutes = useMemo(
    () =>
      getRoutes({
        allRoutes: projectManagerRoutes,
        _user: user,
        auth: authenticated,
        allowances: matchedBranch.allowances,
      }),
    [JSON.stringify(user), authenticated]
  );
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  return useMemo(
    () => (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color="info"
              brand={brandWhite}
              brandName="Matsusho Ltd"
              routes={matchedBranch.routes}
              routePrefix={matchedBranch.prefix}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route element={<EmptyLayout />}>
            <Route index element={<SignInTemplate defaultPath="/projects" />} />
          </Route>
          <Route path="/admin">
            <Route element={<EmptyLayout />}>
              <Route
                index
                element={<SignInTemplate to="/admin/sign-in" defaultPath="/admin/users" />}
              />
            </Route>
            <Route
              exact
              path="sign-in"
              element={<SignIn defaultPath="/admin/users" />}
              key="sign-in"
            />
            <Route element={<UserLayout />}>{renderAdminRoutes}</Route>
            <Route element={<EmptyLayout />}>
              <Route path="*" element={<MyOutlet to="/admin/sign-in" />} />
            </Route>
          </Route>
          <Route path="/operator">
            <Route element={<EmptyLayout />}>
              <Route
                index
                element={<SignInTemplate to="/operator/sign-in" defaultPath="/operator/projects" />}
              />
            </Route>
            <Route
              exact
              path="sign-in"
              element={<SignIn defaultPath="/operator/projects" />}
              key="sign-in"
            />
            <Route element={<UserLayout />}>{renderBusinessRoutes}</Route>
            <Route element={<EmptyLayout />}>
              <Route path="*" element={<MyOutlet to="/operator/sign-in" />} />
            </Route>
          </Route>
          <Route path="/viewer">
            <Route element={<EmptyLayout />}>
              <Route index element={<SignInTemplate to="/viewer/sign-in" />} />
            </Route>
            <Route
              exact
              path="sign-in"
              element={<SignIn defaultPath="/viewer/drawings/1" />}
              key="sign-in"
            />
            <Route element={<UserLayout />}>{renderViewerRoutes}</Route>
            <Route element={<EmptyLayout />}>
              <Route path="*" element={<MyOutlet to="/viewer/sign-in" />} />
            </Route>
          </Route>
          <Route path="/project_manager">
            <Route element={<EmptyLayout />}>
              <Route
                index
                element={
                  <SignInTemplate
                    to="/project_manager/sign-in"
                    defaultPath="/project_manager/projects"
                  />
                }
              />
            </Route>
            <Route
              exact
              path="sign-in"
              element={<SignIn defaultPath="/project_manager/projects" />}
              key="sign-in"
            />
            <Route element={<UserLayout />}>{renderPmRoutes}</Route>
            <Route element={<EmptyLayout />}>
              <Route path="*" element={<MyOutlet to="/project_manager/sign-in" />} />
            </Route>
          </Route>
          <Route element={<UserLayout />}>{renderRoutes}</Route>
          <Route exact path="/sign-in" element={<SignIn />} key="sign-in" />
          <Route exact path="/forgot-pw" element={<ForgotPassword />} key="forgot-pw" />
          <Route exact path="/reset-password" element={<ResetPassword />} key="reset-pw" />
          <Route element={<EmptyLayout />}>
            <Route path="*" element={<MyOutlet />} />
          </Route>
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    ),
    [darkMode, layout, pathname, authenticated, user]
  );
}
