/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Profile from "layouts/profile";
// @mui icons
import Icon from "@mui/material/Icon";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import UserList from "./layouts/users/List";
import UserSingleNew from "./layouts/users/Single/New";
import UserSingleEdit from "./layouts/users/Single/Edit";
import DivisionSingleEdit from "./layouts/divisions/Single/Edit";
import DivisionSingleNew from "./layouts/divisions/Single/New";
import DivisionList from "./layouts/divisions/List";
import RoleSingleNew from "./layouts/roles/Single/New";
import RoleSingleEdit from "./layouts/roles/Single/Edit";
import RoleList from "./layouts/roles/List";
import PermissionSingleEdit from "./layouts/permissions/Single/Edit";
import PermissionSingleNew from "./layouts/permissions/Single/New";
import PermissionList from "./layouts/permissions/List";
import PermissionObjectList from "./layouts/permission_objects/List";
import PermissionObjectSingleEdit from "./layouts/permission_objects/Single/Edit";
import PermissionObjectSingleNew from "./layouts/permission_objects/Single/New";
import PermissionOperatorSingleEdit from "./layouts/permission_operators/Single/Edit";
import PermissionOperatorSingleNew from "./layouts/permission_operators/Single/New";
import PermissionOperatorList from "./layouts/permission_operators/List";
import ClientList from "./layouts/clients/List";
import ClientSingleEdit from "./layouts/clients/Single/Edit";
import ClientSingleNew from "./layouts/clients/Single/New";
import PartnerEmployeeList from "./layouts/partner_employees/List";
import PartnerEmployeeSingleEdit from "./layouts/partner_employees/Single/Edit";
import PartnerEmployeeSingleNew from "./layouts/partner_employees/Single/New";
import EmployeeSingleNew from "./layouts/employees/Single/New";
import EmployeeSingleEdit from "./layouts/employees/Single/Edit";
import EmployeeList from "./layouts/employees/List";
import PartnerSingleNew from "./layouts/partners/Single/New";
import PartnerSingleEdit from "./layouts/partners/Single/Edit";
import PartnerList from "./layouts/partners/List";

const adminRoutes = [
  {
    route: "users/new",
    object: "User",
    operator: "create",
    component: <UserSingleNew />,
    key: "users-new",
  },
  {
    route: "users/:id/edit",
    exact: false,
    object: "User",
    operator: "update",
    component: <UserSingleEdit />,
    key: "users-edit",
  },
  {
    route: "users/:id",
    exact: false,
    object: "User",
    operator: "view",
    component: <UserSingleEdit />,
    key: "users-show",
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    object: "User",
    operator: "viewAny",
    icon: <Icon fontSize="small">group</Icon>,
    route: "users",
    component: <UserList />,
  },
  {
    type: "collapse",
    name: "Divisions",
    key: "divisions",
    object: "Division",
    operator: "viewAny",
    icon: <GroupWorkIcon fontSize="small" />,
    route: "divisions",
    component: <DivisionList />,
  },
  {
    route: "divisions/:id",
    exact: false,
    component: <DivisionSingleEdit />,
    key: "divisions-show",
    object: "Division",
    operator: "view",
  },
  {
    route: "divisions/:id/edit",
    exact: false,
    component: <DivisionSingleEdit />,
    key: "divisions-edit",
    object: "Division",
    operator: "update",
  },
  {
    route: "divisions/new",
    component: <DivisionSingleNew />,
    key: "divisions-new",
    object: "Division",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Permissions",
    key: "permissions",
    icon: <ControlCameraIcon fontSize="small" />,
    route: "permissions",
    component: <PermissionList />,
    object: "Permission",
    operator: "viewAny",
  },
  {
    route: "permissions/:id",
    exact: false,
    component: <PermissionSingleEdit />,
    key: "permissions-show",
    object: "Permission",
    operator: "view",
  },
  {
    route: "permissions/:id/edit",
    exact: false,
    component: <PermissionSingleEdit />,
    key: "permissions-edit",
    object: "Permission",
    operator: "update",
  },
  {
    route: "permissions/new",
    component: <PermissionSingleNew />,
    key: "permissions-new",
    object: "Permission",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Permission Objects",
    key: "permission-objects",
    icon: <Icon fontSize="small">group</Icon>,
    route: "permission-objects",
    component: <PermissionObjectList />,
    object: "PermissionObject",
    operator: "viewAny",
  },
  {
    route: "permission-objects/:id",
    exact: false,
    component: <PermissionObjectSingleEdit />,
    key: "permission-objects-show",
    object: "PermissionObject",
    operator: "view",
  },
  {
    route: "permission-objects/:id/edit",
    exact: false,
    component: <PermissionObjectSingleEdit />,
    key: "permission-objects-edit",
    object: "PermissionObject",
    operator: "update",
  },
  {
    route: "permission-objects/new",
    component: <PermissionObjectSingleNew />,
    key: "permission-objects-new",
    object: "PermissionObject",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Permission Operators",
    key: "permission-operators",
    icon: <Icon fontSize="small">group</Icon>,
    route: "permission-operators",
    component: <PermissionOperatorList />,
    object: "PermissionOperator",
    operator: "viewAny",
  },
  {
    route: "permission-operators/:id",
    exact: false,
    component: <PermissionOperatorSingleEdit />,
    key: "permission-operators-show",
    object: "PermissionOperator",
    operator: "view",
  },
  {
    route: "permission-operators/:id/edit",
    exact: false,
    component: <PermissionOperatorSingleEdit />,
    key: "permission-operators-edit",
    object: "PermissionOperator",
    operator: "update",
  },
  {
    route: "permission-operators/new",
    component: <PermissionOperatorSingleNew />,
    key: "permission-operators-new",
    object: "PermissionOperator",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Roles",
    key: "roles",
    icon: <Icon fontSize="small">group</Icon>,
    route: "roles",
    component: <RoleList />,
    object: "Role",
    operator: "viewAny",
  },
  {
    route: "roles/:id",
    exact: false,
    component: <RoleSingleEdit />,
    key: "roles-show",
    object: "Role",
    operator: "view",
  },
  {
    route: "roles/:id/edit",
    exact: false,
    component: <RoleSingleEdit />,
    key: "roles-edit",
    object: "Role",
    operator: "update",
  },
  {
    route: "roles/new",
    component: <RoleSingleNew />,
    key: "roles-new",
    object: "Role",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "clients",
    component: <ClientList />,
    object: "Client",
    operator: "viewAny",
  },
  {
    route: "clients/:id",
    exact: false,
    component: <ClientSingleEdit />,
    key: "clients-show",
    object: "Client",
    operator: "view",
  },
  {
    route: "clients/:id/edit",
    exact: false,
    component: <ClientSingleEdit />,
    key: "clients-edit",
    object: "Client",
    operator: "update",
  },
  {
    route: "clients/new",
    component: <ClientSingleNew />,
    key: "clients-new",
    object: "Client",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">group</Icon>,
    route: "employees",
    component: <EmployeeList />,
    object: "Employee",
    operator: "viewAny",
  },
  {
    route: "employees/:id",
    exact: false,
    component: <EmployeeSingleEdit />,
    key: "employees-show",
    object: "Employee",
    operator: "view",
  },
  {
    route: "employees/:id/edit",
    exact: false,
    component: <EmployeeSingleEdit />,
    key: "employees-edit",
    object: "Employee",
    operator: "update",
  },
  {
    route: "employees/new",
    component: <EmployeeSingleNew />,
    key: "employees-new",
    object: "Employee",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Partners",
    key: "partners",
    icon: <Icon fontSize="small">group</Icon>,
    route: "partners",
    component: <PartnerList />,
    object: "Partner",
    operator: "viewAny",
  },
  {
    route: "partners/:id",
    exact: false,
    component: <PartnerSingleEdit />,
    key: "partners-show",
    object: "Partner",
    operator: "view",
  },
  {
    route: "partners/:id/edit",
    exact: false,
    component: <PartnerSingleEdit />,
    key: "partners-edit",
    object: "Partner",
    operator: "update",
  },
  {
    route: "partners/new",
    component: <PartnerSingleNew />,
    key: "partners-new",
    object: "Partner",
    operator: "create",
  },
  {
    type: "collapse",
    name: "PartnerEmployees",
    key: "partnerEmployees",
    icon: <Icon fontSize="small">group</Icon>,
    route: "partner-employees",
    component: <PartnerEmployeeList />,
    object: "PartnerEmployee",
    operator: "viewAny",
  },
  {
    route: "partner-employees/:id",
    exact: false,
    component: <PartnerEmployeeSingleEdit />,
    key: "partner-employees-show",
    object: "PartnerEmployee",
    operator: "view",
  },
  {
    route: "partner-employees/:id/edit",
    exact: false,
    component: <PartnerEmployeeSingleEdit />,
    key: "partner-employees-edit",
    object: "PartnerEmployee",
    operator: "update",
  },
  {
    route: "partner-employees/new",
    component: <PartnerEmployeeSingleNew />,
    key: "partner-employees-new",
    object: "PartnerEmployee",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "profile",
    component: <Profile />,
  },
];

export default adminRoutes;
