import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import { DropzoneArea } from "mui-file-dropzone";
import { useMountedState } from "react-use";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { processError } from "../../../../utils/services/api";
import DrawingServices from "../../../../services/DrawingServices";
import { SUPPORTED_IMG_FORMATS } from "../../../../utils/config";
import useFetch from "../../../../utils/hooks/useFetch";
import UserServices from "../../../../services/UserServices";
import PartnerEmployeeServices from "../../../../services/PartnerEmployeeServices";
import SubmitWithConfirmBtn from "../../../../components/SubmitWithConfirmBtn";
import PiecesView from "./PiecesView";

// eslint-disable-next-line react/prop-types
export default function DrawingSingleEdit({ prefix = "" }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const { id: drawingId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [drawing, setDrawing] = useFetch({
    fetchFn: async () => DrawingServices.detail({ id: drawingId }),
    deps: [drawingId],
  });
  const [users] = useFetch({
    initialState: [],
    fetchFn: async () => UserServices.index(),
  });
  const [designers] = useFetch({
    initialState: [],
    fetchFn: async () => PartnerEmployeeServices.index({ partner_type: "design" }),
  });
  const [constructors] = useFetch({
    initialState: [],
    fetchFn: async () => PartnerEmployeeServices.index({ partner_type: "construction" }),
  });
  const validationSchema = yup.object({
    name: yup.string().max(30).required(),
    rev_number: yup.string().max(15).required(),
    piece_amount: yup.number().integer().min(0),
    is_additional: yup.mixed().required(),
    memo: yup.string(),
    designer_id: yup.number().positive(),
    constructor_id: yup.number().positive(),
    assigned_to_id: yup.number().positive().required(),
    deletingPieces: yup.array().of(yup.number().positive()),
    image: yup
      .mixed()
      .test(
        "FILE_FORMAT",
        t("Uploaded file has unsupported format."),
        (value) => !value || (value && SUPPORTED_IMG_FORMATS.includes(value.type))
      ),
  });
  const navigate = useNavigate();
  /* eslint-disable camelcase */
  const handleCreate = async ({ deletingPieces, ...values }, { setSubmitting }) => {
    try {
      const payload = new FormData();
      ["name", "designer_id", "constructor_id", "assigned_to_id"].forEach((col) => {
        if (values[col]) {
          payload.append(`drawing[${col}]`, values[col]);
        }
      });
      ["rev_number", "memo", "image", "piece_amount", "is_additional"].forEach((col) => {
        if (values[col] !== undefined) {
          payload.append(`revision[${col}]`, values[col]);
        }
      });
      deletingPieces.forEach((piece_id) => payload.append("deleting_pieces[]", piece_id));
      const {
        data: { data: fetchedDrawing },
      } = await DrawingServices.update({
        payload,
        id: drawingId,
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setDrawing(fetchedDrawing);
      navigate(`${prefix}/projects/${fetchedDrawing.project_id}/drawings`);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  /* eslint-enable camelcase */
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Create Drawing")}
              </MDTypography>
            </MDBox>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{
                name: drawing.name || "",
                rev_number: "",
                piece_amount: "",
                deletingPieces: [],
                memo: "",
                is_additional: +(drawing?.latest_revision?.is_additional || 0),
                designer_id: designers.length > 0 ? drawing.designer_id || "" : "",
                constructor_id: constructors.length > 0 ? drawing.constructor_id || "" : "",
                assigned_to_id: users.length > 0 ? drawing.assigned_to_id || "" : "",
                image: undefined,
              }}
              validationSchema={validationSchema}
              onSubmit={handleCreate}
            >
              {({
                isValid,
                errors: { image: imageError },
                setFieldValue,
                values: { deletingPieces },
                submitForm,
                isSubmitting,
                resetForm,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <MDBox p={2}>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <Field
                                component={TextField}
                                name="name"
                                type="text"
                                label={t("Drawing Name")}
                                variant="standard"
                                fullWidth
                                required
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <Field
                                component={TextField}
                                name="rev_number"
                                type="text"
                                label={t("Revision Number")}
                                variant="standard"
                                fullWidth
                                required
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="assigned_to_id"
                                  name="assigned_to_id"
                                  select
                                  variant="standard"
                                  label={t("Drawing Assigned To")}
                                  required
                                >
                                  {users.map(({ name, id: userId }) => (
                                    <MenuItem value={userId} key={userId}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Field>
                                {t("Current Value:")} {drawing?.assigned_to?.name}
                              </FormControl>
                            </MDBox>
                          </Grid>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="is_additional"
                                  name="is_additional"
                                  select
                                  variant="standard"
                                  label={t("Order Type")}
                                  required
                                >
                                  <MenuItem value={0}>{t("Normal Order")}</MenuItem>
                                  <MenuItem value={1}>{t("Additional Order")}</MenuItem>
                                </Field>
                              </FormControl>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="designer_id"
                                  name="designer_id"
                                  select
                                  variant="standard"
                                  label={t("Drawing Designer")}
                                >
                                  <MenuItem value="">{t("No assignee")}</MenuItem>
                                  {designers.map(
                                    ({ name, id: designerId, partner: { name: partnerName } }) => (
                                      <MenuItem value={designerId} key={designerId}>
                                        {name} - {partnerName}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                                {t("Current Value:")} {drawing?.designed_by?.name}
                              </FormControl>
                            </MDBox>
                          </Grid>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="constructor_id"
                                  name="constructor_id"
                                  select
                                  variant="standard"
                                  label={t("Drawing Constructor")}
                                >
                                  <MenuItem value="">{t("No assignee")}</MenuItem>
                                  {constructors.map(
                                    ({
                                      name,
                                      id: constructorId,
                                      partner: { name: partnerName },
                                    }) => (
                                      <MenuItem value={constructorId} key={constructorId}>
                                        {name} - {partnerName}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                                {t("Current Value:")} {drawing?.constructed_by?.name}
                              </FormControl>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <MDTypography variant="h6">{t("Pieces Details")}</MDTypography>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <MDBox mb={2}>
                              <Field
                                component={TextField}
                                name="piece_amount"
                                type="number"
                                label={t("Edit Piece Amount")}
                                variant="standard"
                                fullWidth
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <PiecesView
                          isSubmitting={isSubmitting}
                          handleChange={({ value, checked }) =>
                            setFieldValue(
                              "deletingPieces",
                              checked
                                ? [...deletingPieces, value]
                                : deletingPieces.filter((pieceId) => value !== pieceId)
                            )
                          }
                          drawingId={drawingId}
                          checkedIds={deletingPieces}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item md={6}>
                      <MDBox p={2}>
                        <MDBox mb={2}>
                          <InputLabel sx={{ mb: 1 }}>{t("Drawing Image")}</InputLabel>
                          <DropzoneArea
                            maxFileSize={20000000}
                            acceptedFiles={SUPPORTED_IMG_FORMATS}
                            dropzoneText={t("Image Upload")}
                            filesLimit={1}
                            onChange={([file]) => setFieldValue("image", file)}
                          />
                          {imageError && (
                            <Alert variant="outlined" severity="error">
                              {imageError}
                            </Alert>
                          )}
                        </MDBox>
                        <Field
                          component={TextField}
                          name="memo"
                          multiline
                          minRows={5}
                          maxRows={10}
                          type="text"
                          label={t("Memo")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox px={2} mt={4} mb={2} sx={{ justifyContent: "center", display: "flex" }}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={resetForm}
                      sx={{ mr: 2 }}
                    >
                      {t("Reset")}
                    </MDButton>
                    <SubmitWithConfirmBtn
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                      handleSubmit={submitForm}
                      message={t("Confirm change drawing?")}
                    />
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
