import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import { Stack } from "@mui/material";
import { useSanctum } from "react-sanctum";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import { isAllowed, PROGRESSES } from "../../../utils/config";
import useFetch from "../../../utils/hooks/useFetch";
import StageTypeServices from "../../../services/StageTypeServices";

export default function Operations() {
  const { t } = useTranslation();
  const { project_id: projectId } = useParams();
  const { user, authenticated } = useSanctum();
  const [stageTypes] = useFetch({
    fetchFn: async () => StageTypeServices.index(),
    deps: [projectId],
    initialState: [],
  });
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Select Business Operations")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <Grid container spacing={3} mb={3}>
                {isAllowed({ object: "Project", operator: "update", user, authenticated }) && (
                  <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
                    <MDButton
                      variant="contained"
                      href={`/operator/projects/${projectId}/edit`}
                      color="primary"
                    >
                      {t("Edit Project")}
                    </MDButton>
                  </Grid>
                )}
                {isAllowed({ object: "Drawing", operator: "viewAny", user, authenticated }) && (
                  <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
                    <MDButton
                      variant="contained"
                      href={`/operator/projects/${projectId}/drawings`}
                      color="primary"
                    >
                      {t("drawing_management")}
                    </MDButton>
                  </Grid>
                )}
                {stageTypes
                  .filter((type) =>
                    isAllowed({
                      object: PROGRESSES[type.name].permObject,
                      operator: "update",
                      user,
                      authenticated,
                    })
                  )
                  .map((type) => (
                    <Grid item xs={12} md={3} sx={{ textAlign: "center" }} key={type.id}>
                      <MDButton
                        variant="contained"
                        href={`/operator/projects/${projectId}/${PROGRESSES[type.name].path}`}
                        color="primary"
                      >
                        {t(PROGRESSES[type.name].pathName)}
                      </MDButton>
                    </Grid>
                  ))}
              </Grid>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Link to="/operator/projects">
                  <MDButton color="info" sx={{ marginLeft: "auto" }}>
                    {t("back to projects list")}
                  </MDButton>
                </Link>
              </Stack>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
