import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index({ drawingId, ...params }) {
    return authClient.get(`/api/drawings/${drawingId}/revisions`, { params });
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/revisions/${id}`, { ...payload });
  },
  async detail({ id }) {
    return authClient.get(`/api/revisions/${id}`);
  },
};
