import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "../../../../components/MDBox";
import DrawingServices from "../../../../services/DrawingServices";
import useFetch from "../../../../utils/hooks/useFetch";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import DrawingConfirmButton from "../../../../components/Drawing/ConfirmButton";
import PiecesInfo from "../../../revisions/components/PiecesInfo";
import OperationsButton from "../../../../components/Drawing/OperationsButton";
// eslint-disable-next-line react/prop-types,no-unused-vars
export default function DrawingViewer() {
  const { t } = useTranslation();
  const { id: drawingId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [drawing] = useFetch({
    fetchFn: async () => DrawingServices.detail({ id: drawingId }),
    deps: [drawingId],
    initialState: false,
  });
  const navigate = useNavigate();
  /* eslint-enable camelcase */
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {drawing ? (
              <>
                <MDBox
                  mx={2}
                  mt={-3}
                  mb={3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    {drawing.project.name}-{drawing.name}
                  </MDTypography>
                </MDBox>
                <MDBox my={1} sx={{ justifyContent: "center", display: "flex" }}>
                  <OperationsButton drawing={drawing} />
                </MDBox>
                <MDBox my={1} sx={{ justifyContent: "center", display: "flex" }}>
                  <PiecesInfo
                    drawingId={drawing.id}
                    title={t("Working Progression")}
                    variant="gradient"
                    color="info"
                  />
                </MDBox>
                {drawing?.latest_revision?.image && (
                  <MDBox my={1} sx={{ justifyContent: "center", display: "flex" }}>
                    <DrawingConfirmButton image={drawing.latest_revision.image} />
                  </MDBox>
                )}
                <MDBox py={1} mb={4} sx={{ justifyContent: "center", display: "flex" }}>
                  <MDButton
                    color="info"
                    onClick={() => navigate(`revisions/${drawing?.latest_revision?.id}/edit`)}
                  >
                    {t("Drawing Detail")}
                  </MDButton>
                </MDBox>
              </>
            ) : (
              <CircularProgress size={20} />
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
