import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "../MDButton";

function SubmitWithConfirmBtn({
  isSubmitting,
  isValid,
  handleSubmit,
  message,
  title,
  submitText,
  btnText,
  variant,
  btnColor,
  loadingIcon,
  normalIcon,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onSubmit = () => {
    handleClose();
    handleSubmit();
  };

  return (
    <>
      <MDButton
        variant={variant}
        color={btnColor}
        startIcon={isSubmitting ? loadingIcon : normalIcon}
        disabled={isSubmitting || !isValid}
        onClick={handleOpen}
      >
        {t(btnText)}
      </MDButton>
      <Dialog open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {t(title)}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{t(message)}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "between" }}>
          <Button onClick={handleClose}>{t("No")}</Button>
          <MDButton variant="gradient" color="info" onClick={onSubmit}>
            {t(submitText)}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
SubmitWithConfirmBtn.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  submitText: PropTypes.string,
  btnText: PropTypes.string,
  variant: PropTypes.string,
  btnColor: PropTypes.string,
  loadingIcon: PropTypes.element,
  normalIcon: PropTypes.element,
};

SubmitWithConfirmBtn.defaultProps = {
  message: "Are you sure to create",
  title: "Creation Confirm Title",
  submitText: "Create Button",
  btnText: "Save",
  variant: "gradient",
  btnColor: "info",
  loadingIcon: <CircularProgress size={20} color="white" />,
  normalIcon: <SaveIcon />,
};

export default SubmitWithConfirmBtn;
