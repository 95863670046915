import { useState, useEffect } from "react";
import { useMountedState } from "react-use";
import { processError } from "../../services/api";

export const fetchData = async ({ fetchFn, isMounted, setData, setFetching = () => {} }) => {
  setFetching(true);
  try {
    const {
      data: { data: fetchedData },
    } = await fetchFn();
    if (!isMounted) return;
    setFetching(false);
    setData(fetchedData);
  } catch (error) {
    processError(error);
    setFetching(false);
    // todo: redirect to global route
  }
};

const useFetch = ({ fetchFn, deps = [], initialState = {} }) => {
  const isMounted = useMountedState();
  const [data, setData] = useState(initialState);

  useEffect(() => {
    (async () => fetchData({ fetchFn, isMounted, setData }))();
  }, deps);
  return [data, setData];
};

export default useFetch;
