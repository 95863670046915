import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import DataTable from "../../../../examples/Tables/DataTable";
import DrawingServices from "../../../../services/DrawingServices";
import ItemActions from "../../../_common/ItemActions";
import StageServices from "../../../../services/StageServices";
import useFetch from "../../../../utils/hooks/useFetch";
import PieceStat from "../../../../components/Piece/Stat";
import PieceProgressList from "../../../../components/Piece/Progresses";
import DrawingShowButton from "../../../../components/Drawing/ShowButton";
import MultipleCheckFormWithDate from "../../../../components/Piece/Progresses/MultipleCheckFormWithDate";
import PieceProgressesFormWithDate from "../../../../components/Piece/Progresses/PieceProgressesFormWithDate";
import QRModalButton from "../../../../components/Drawing/QRModalButton";

/* eslint-disable no-unused-vars,camelcase,react/prop-types */
export default function ArrivalList({ prefix = "" }) {
  const { t, i18n } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const { project_id } = useParams();
  const handleFetchData = async ({
    pageIndex = 1,
    pageSize = 50,
    sortBy,
    globalFilter,
    filters,
  } = {}) => {
    // Give this fetch an ID
    const mappedFilters = filters.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await DrawingServices.index({
      page: pageIndex + 1,
      pageSize,
      sortBy,
      globalFilter,
      project_id,
      ...mappedFilters,
      stage: "arrival_check",
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };
  const [stages] = useFetch({
    fetchFn: async () => StageServices.index({ type_name: "arrival_check" }),
    initialState: [],
  });
  const [produced] = useFetch({
    fetchFn: async () =>
      StageServices.index({ type_name: "shipping_registration", name: "registered" }),
    initialState: [],
  });
  const initialCols = [
    {
      Header: t("Drawing Name"),
      accessor: "name",
      align: "left",
      /* eslint-disable react/no-unstable-nested-components,react/prop-types, camelcase */
      Cell: ({ row: { original } }) => (
        <PieceProgressList
          drawing={original}
          type="arrival_check"
          stackForm={MultipleCheckFormWithDate}
          piecesForm={PieceProgressesFormWithDate}
        />
      ),
      /* eslint-enable react/no-unstable-nested-components,react/prop-types, camelcase */
    },
    {
      Header: t("Action"),
      disableSortBy: true,
      accessor: "action",
      align: "center",
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({ value, row: { original } }) => (
        <>
          {original?.latest_revision && (
            <>
              <ItemActions
                editLink={`${prefix}/drawings/${value}/revisions/${original.latest_revision.id}/edit`}
                key={value}
                object="Drawing"
                editText={t("Detail")}
              />
              {original.latest_revision.image && (
                <DrawingShowButton image={original.latest_revision.image} />
              )}
            </>
          )}
          <QRModalButton drawing={original} />
        </>
      ),
      /* eslint-enable react/no-unstable-nested-components,react/prop-types */
    },
  ];
  const [columns, setColumns] = useState(initialCols);
  useEffect(() => {
    let producedCol = null;
    if (produced.length > 0) {
      const stage = produced[0];
      producedCol = {
        disableSortBy: true,
        Header: t("Shipping Status"),
        accessor: ({ stats }) => stats?.find(({ id: sId }) => stage.id === sId)?.count || 0,
        align: "center",
        id: "arrival-completed",
        /* eslint-disable react/no-unstable-nested-components,camelcase,react/prop-types */
        Cell: ({ value, row: { original } }) => (
          <PieceStat merge key={original.id} drawing={original} stat={value} stage={stage} />
        ),
        /* eslint-enable react/no-unstable-nested-components,camelcase,react/prop-types */
      };
    }
    const newCols = stages.map((stage) => ({
      disableSortBy: true,
      Header: stage[`title_${i18n.language}`] || stage.title_en,
      accessor: ({ stats }) => stats?.find(({ id: sId }) => stage.id === sId)?.count || 0,
      align: "center",
      id: stage.name,
      /* eslint-disable react/no-unstable-nested-components,camelcase,react/prop-types */
      Cell: ({ value, row: { original } }) => (
        <PieceStat key={original.id} drawing={original} stat={value} stage={stage} />
      ),
      /* eslint-enable react/no-unstable-nested-components,camelcase,react/prop-types */
    }));
    const [a, ...cols] = initialCols;
    if (!isMounted) return;
    setColumns([a, producedCol, ...newCols, ...cols].filter(Boolean));
  }, [JSON.stringify(stages.map(({ id }) => id)), JSON.stringify(produced.map(({ id }) => id))]);
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [JSON.stringify(rows)]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Drawings Index")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                manualFilters
                manualGlobalFilter
                canSearch
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
