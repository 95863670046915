import { useSanctum } from "react-sanctum";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function RequiredAuth({ children, to }) {
  const { authenticated } = useSanctum();
  const location = useLocation();
  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={to} state={{ from: location }} replace />;
  }

  return children;
}
RequiredAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string,
};
RequiredAuth.defaultProps = {
  to: "/sign-in",
};
export default RequiredAuth;
