import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import PermissionObjectServices from "../../../services/PermissionObjectServices";
import ItemActions from "../../_common/ItemActions";
import NewAction from "../../_common/NewAction";

export default function PermissionObjectList() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy, filters } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await PermissionObjectServices.index({ page: pageIndex + 1, pageSize, sortBy, filters });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    { Header: t("Id"), accessor: "id", align: "right" },
    {
      Header: t("Name"),
      accessor: "name",
      align: "left",
      Cell: ({ value }) => t(value),
    },
    {
      Header: t("Created By"),
      // eslint-disable-next-line camelcase
      accessor: ({ created_by }) => {
        // eslint-disable-next-line camelcase
        const { name } = created_by || {};
        return name;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { creator_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/admin/users/${creator_id}/edit`}>
          {value}
        </Link>
      ),
      align: "left",
      disableSortBy: true,
    },
    {
      Header: t("Created At"),
      id: "created_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ created_at }) => format(parseISO(created_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Updated At"),
      id: "updated_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ updated_at }) => format(parseISO(updated_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Action"),
      disableSortBy: true,
      accessor: "action",
      align: "center",
      // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
      Cell: ({ value, state: { pageIndex, pageSize, sortBy, filters } }) => (
        <ItemActions
          object="PermissionObject"
          editLink={`/admin/permission-objects/${value}/edit`}
          key={value}
          onDelete={async () => {
            await PermissionObjectServices.destroy({ id: value });
            await handleFetchData({ pageIndex, pageSize, sortBy, filters });
          }}
        />
      ),
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("PermissionObjects Index")}
              </MDTypography>
              <NewAction object="PermissionObject" prefix="/admin" />
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
