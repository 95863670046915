/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useSanctum } from "react-sanctum";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import isEmailValidator from "validator/lib/isEmail";
import axios from "../../../utils/config/vendor/axios";
import { processError } from "../../../utils/services/api";

function ForgotPassword() {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t("Email is required"))
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";

  const { authenticated } = useSanctum();

  const handleForgotPw = async ({ email }, { setSubmitting }) => {
    try {
      const response = await axios.post("forgot-password", { email });
      const { data: { message } = {} } = response;
      toast.success(t(message));
      navigate(from, { replace: true });
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  if (authenticated) {
    return <Navigate to={from} replace />;
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("Forgot Password")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleForgotPw}
          >
            {({ isValid, submitForm, isSubmitting }) => (
              <Form>
                <MDBox mb={4}>
                  <Field
                    component={TextField}
                    name="email"
                    autoComplete="email"
                    type="text"
                    label={t("Email")}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  {isSubmitting && <CircularProgress />}
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                  >
                    {t("Recover my password")}
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography
            variant="button"
            component={Link}
            to="/sign-in"
            color="info"
            fontWeight="medium"
            textGradient
          >
            {t("Log in")}
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
