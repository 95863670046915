import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Dialog from "@mui/material/Dialog";
import MDButton from "../../MDButton";
import PdfViewer from "../../PdfViewer";

function DrawingShowButton({ image }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MDButton color="success" onClick={handleOpen} sx={{ ml: 2 }} startIcon={<ZoomInIcon />}>
        {t("Image")}
      </MDButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="large"
            sx={{
              position: "absolute",
              right: -8,
              top: -8,
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
          {image.toLowerCase().includes(".pdf") ? (
            <PdfViewer src={image} />
          ) : (
            <img src={image} alt="" width="100%" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
DrawingShowButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.string.isRequired,
};

export default DrawingShowButton;
