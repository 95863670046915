import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { Select, Stack, TextField } from "@mui/material";
import { toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../examples/Tables/DataTable";
import PieceServices from "../../../services/PieceServices";
import PieceInfoButton from "../../../layouts/revisions/components/PieceInfoButton";
import ProgressStack from "./ProgressStack";
import MDButton from "../../MDButton";
import RevisionServices from "../../../services/RevisionServices";
import { processError } from "../../../utils/services/api";
import { PROGRESSES } from "../../../utils/config";
/* eslint-disable camelcase */
function PieceProgressesForm({
  steps,
  templateSteps,
  type,
  drawing,
  users,
  user,
  stages,
  incr,
  stack,
}) {
  const { latest_revision: revision } = drawing;
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const [submitVals, setVals] = useState({});
  const skipPageResetRef = useRef(false);
  const navigate = useNavigate();
  const submitForm = async () => {
    try {
      setLoading(true);
      await RevisionServices.update({
        id: revision.id,
        pieces: {
          payload: submitVals,
          type: PROGRESSES[type].relations,
        },
      });
      toast.success(t("Succeeded"));
      // afterSubmit(updated);
      navigate(0);
    } catch (error) {
      processError(error);
    }
    setLoading(false);
  };
  const ProgressStackForm = stack;
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy, filters } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await PieceServices.indexByRev({
      revisionId: revision.id,
      with: PROGRESSES[type].relations,
      withStep: `${PROGRESSES[type].relation}Steps`,
      of_type: type,
      page: pageIndex + 1,
      pageSize,
      sortBy,
      filters,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        assign: id,
        piece_id: id,
      }))
    );
    setLoading(false);
  };
  const columns = [
    {
      Header: t("Name"),
      accessor: "name",
      align: "left",
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({ value, row: { original } }) => (
        <PieceInfoButton piece={original} title={value} variant="text" color="primary" />
      ),
      /* eslint-enable react/no-unstable-nested-components,react/prop-types */
    },
    {
      Header: t("Statuses"),
      accessor: "piece_id",
      align: "center",
      disableSortBy: true,
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({ value, row: { original } }) => {
        const progresses = original[PROGRESSES[type].plural];
        const initialSteps = original[`${type}_steps`];
        return (
          <ProgressStackForm
            steps={steps}
            stages={stages}
            value={value}
            drawing={drawing}
            initialSteps={initialSteps}
            templateSteps={templateSteps}
            progresses={progresses}
            submitStage={submitVals[value]}
            setVals={({ col, newVal }) => {
              const vals = submitVals;
              vals[value] = {
                ...vals[value],
                [col]: newVal,
              };
              if (!isMounted) return;
              setVals(vals);
            }}
          />
        );
      },
      /* eslint-enable react/no-unstable-nested-components,react/prop-types */
    },
    {
      Header: t("Assigned To"),
      accessor: "assign",
      align: "center",
      disableSortBy: true,
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({ value: pId }) => (
        <Select
          onChange={({ target: { value } }) => {
            const vals = submitVals;
            vals[pId] = { ...vals[pId], assigned_id: value };
            if (!isMounted) return;
            setVals(vals);
          }}
          sx={{ minWidth: "100px" }}
        >
          {users.map(({ name, id: uId }) => (
            <MenuItem value={uId} key={uId}>
              {uId === user.id ? t("self") : name}
            </MenuItem>
          ))}
        </Select>
      ),
      /* eslint-enable react/no-unstable-nested-components,react/prop-types */
    },
    {
      Header: t("Comment"),
      accessor: "id",
      align: "center",
      disableSortBy: true,
      /* eslint-disable react/no-unstable-nested-components,react/prop-types */
      Cell: ({ value: pId }) => (
        <TextField
          multiline
          label={t("Put a comment")}
          onChange={({ target: { value } }) => {
            const vals = submitVals;
            vals[pId] = { ...vals[pId], memo: value };
            if (!isMounted) return;
            setVals(vals);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ alignSelf: "flex-start", mt: 2, ml: 1 }}>
                <InsertCommentIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
      /* eslint-enable react/no-unstable-nested-components,react/prop-types */
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  // https://react-table.tanstack.com/docs/api/useRowState for set row and cell state
  // https://react-table.tanstack.com/docs/faq
  return (
    <>
      <DataTable
        initialSorts={[{ id: "name", desc: false }]}
        table={{ columns, rows }}
        manualPagination
        onFetchData={handleFetchData}
        pageCount={pageCount}
        skipPageReset={skipPageResetRef.current}
        loading={loading}
        total={total}
        incr={incr}
        extra={{ submitVals }}
      />
      <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
        <MDButton
          variant="gradient"
          color="info"
          startIcon={loading ? <CircularProgress size={20} color="white" /> : <SaveIcon />}
          disabled={loading}
          onClick={submitForm}
          sx={{ mx: "auto" }}
        >
          {t("Change")}
        </MDButton>
      </Stack>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
PieceProgressesForm.propTypes = {
  drawing: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  templateSteps: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  incr: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  steps: PropTypes.array,
  stack: PropTypes.elementType,
};
PieceProgressesForm.defaultProps = {
  steps: [],
  stack: ProgressStack,
};
export default PieceProgressesForm;
