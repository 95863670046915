import PropTypes from "prop-types";
import { Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TextField } from "formik-mui";
import { processError } from "../../../utils/services/api";
import MDButton from "../../MDButton";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import RevisionServices from "../../../services/RevisionServices";
import { PROGRESSES } from "../../../utils/config";

function MultipleCheckForm({ type, drawing, stages, user, users }) {
  const { latest_revision: revision } = drawing;
  const { t, i18n } = useTranslation();
  const validationSchema = yup.object({
    assigned_id: yup.number().positive().required(),
    stage_id: yup.number().positive().required(),
  });

  const navigate = useNavigate();
  const handleEdit = async (payload, { setSubmitting, resetForm }) => {
    try {
      await RevisionServices.update({
        id: revision.id,
        all_pieces: {
          ...payload,
          type: PROGRESSES[type].relations,
          of_type: type,
        },
      });
      toast.success(t("Succeeded"));
      resetForm();
      // afterSubmit(updated);
      navigate(0);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        assigned_id: users.length > 0 ? user?.id || "" : "",
        stage_id: 0,
        memo: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleEdit}
    >
      {/* eslint-disable-next-line camelcase */}
      {({ values: { stage_id }, isValid, submitForm, isSubmitting, setFieldValue }) => (
        <Form>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" mb={2}>
              <MDTypography variant="h4">{t("Simultaneous check")}</MDTypography>
              <MDBox>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    id="assigned_id"
                    name="assigned_id"
                    select
                    variant="standard"
                    label={t("Assigned To")}
                    required
                  >
                    {users.map(({ name, id: uId }) => (
                      <MenuItem value={uId} key={uId}>
                        {uId === user.id ? t("self") : name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </MDBox>
            </Stack>
            <Stack mb={2} spacing={2} direction={{ xs: "column", md: "row" }}>
              {stages.map(({ name, id, ...stage }) => (
                <MDButton
                  // eslint-disable-next-line camelcase
                  variant={stage_id === id ? "contained" : "outlined"}
                  key={id}
                  color="primary"
                  sx={{ minWidth: "100px" }}
                  onClick={() => setFieldValue("stage_id", id)}
                >
                  {stage[`title_${i18n.language}`] || stage.title_en}
                </MDButton>
              ))}
              <Field
                component={TextField}
                name="memo"
                multiline
                minRows={3}
                maxRows={10}
                type="text"
                label={t("Comment")}
                variant="standard"
                fullWidth
              />
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <MDButton
                variant="gradient"
                color="info"
                startIcon={
                  isSubmitting ? <CircularProgress size={20} color="white" /> : <SaveIcon />
                }
                disabled={isSubmitting || !isValid}
                onClick={submitForm}
                sx={{ mx: "auto" }}
              >
                {t("Save")}
              </MDButton>
            </Stack>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}
MultipleCheckForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stages: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default MultipleCheckForm;
