import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MDButton from "../../MDButton";
import MDTypography from "../../MDTypography";
import QRPdfButton from "./qr_pdf_button";

/* eslint-disable camelcase */
function QRModalButton({ drawing, btnTitle, variant, color, sx }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MDButton
        color={color}
        variant={variant}
        onClick={handleOpen}
        sx={sx}
        startIcon={<QrCodeIcon />}
      >
        {t(btnTitle)}
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {t("Select QR Size")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MDTypography variant="h6" sx={{ mb: 3 }}>
            {t("Please select QR size to issue")}
          </MDTypography>
          <Grid container spacing={5} sx={{ mb: 3 }}>
            <Grid item md={4}>
              <QRPdfButton title={t("sm_qr")} drawing={drawing} gridSize={3} amount={24} />
            </Grid>
            <Grid item md={4}>
              <QRPdfButton title={t("md_qr")} drawing={drawing} gridSize={4} amount={12} />
            </Grid>
            <Grid item md={4}>
              <QRPdfButton
                title={t("comment_qr")}
                drawing={drawing}
                gridSize={6}
                amount={6}
                isCommentable
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
QRModalButton.propTypes = {
  drawing: PropTypes.object.isRequired,
  sx: PropTypes.object,
  btnTitle: PropTypes.string,
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

QRModalButton.defaultProps = {
  btnTitle: "QR Code",
  variant: "contained",
  color: "warning",
  sx: { ml: 2 },
};

export default QRModalButton;
