import { Checkbox, FormGroup } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useState } from "react";
import { useMountedState } from "react-use";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { processError } from "../../../../../../utils/services/api";
import RolePermissionServices from "../../../../../../services/RolePermissionServices";

function PermissionCheckbox({ rolePermissions, permissionId, roleId, afterChange, disabled }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [isSubmitting, setSubmitting] = useState(false);
  // eslint-disable-next-line camelcase
  const handleChange = async ({ id, permission_id, role_id } = {}) => {
    setSubmitting(true);
    try {
      if (id) {
        await RolePermissionServices.destroy({
          id,
        });
      } else {
        await RolePermissionServices.create({
          // eslint-disable-next-line camelcase
          permission_id,
          // eslint-disable-next-line camelcase
          role_id,
        });
      }
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setSubmitting(false);
      afterChange();
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  // eslint-disable-next-line camelcase
  const found = rolePermissions.find(({ permission_id }) => permission_id === permissionId);
  return (
    <FormGroup key={permissionId}>
      {isSubmitting || disabled ? (
        <CircularProgress size={20} />
      ) : (
        <Checkbox
          checked={!!found}
          onChange={() => handleChange(found || { permission_id: permissionId, role_id: roleId })}
          disabled={isSubmitting || disabled}
        />
      )}
    </FormGroup>
  );
}
PermissionCheckbox.propTypes = {
  rolePermissions: PropTypes.arrayOf(
    PropTypes.shape({
      permission_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  permissionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  afterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PermissionCheckbox.defaultProps = {
  disabled: false,
};

export default PermissionCheckbox;
