import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MDButton from "../../MDButton";
import PdfViewer from "../../PdfViewer";

function DrawingConfirmButton({ image }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MDButton color="info" onClick={handleOpen}>
        {t("Drawing's Image")}
      </MDButton>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogTitle>
          {t("Drawing Confirmation")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {image?.toLowerCase()?.includes(".pdf") ? (
            <PdfViewer src={image} />
          ) : (
            <img src={image} alt="" width="100%" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
DrawingConfirmButton.propTypes = {
  image: PropTypes.string.isRequired,
};

export default DrawingConfirmButton;
