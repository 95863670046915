import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { Autocomplete, TextField } from "formik-mui";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useMountedState } from "react-use";
import FormControl from "@mui/material/FormControl";
import isEmailValidator from "validator/lib/isEmail";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import UserServices from "../../../../services/UserServices";
import { processError } from "../../../../utils/services/api";
import useFetch from "../../../../utils/hooks/useFetch";
import DivisionServices from "../../../../services/DivisionServices";
import MDInput from "../../../../components/MDInput";
import RoleServices from "../../../../services/RoleServices";

export default function UserSingleEdit() {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .max(255)
      .required(t("Email is required"))
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
    phone: yup.string().max(50),
    name: yup.string().max(30),
    division_id: yup.number().positive(),
  });
  const validationPwSchema = yup.object({
    password: yup.string().required(t("Password is required")).min(8),
    password_confirmation: yup
      .string()
      .required(t("Password confirmation is required"))
      .oneOf([yup.ref("password"), null], t("Passwords must match"))
      .min(8),
  });
  const { id } = useParams();
  const [user, setUser] = useFetch({
    fetchFn: async () => UserServices.detail({ id }),
    deps: [id],
  });
  const [divisions] = useFetch({
    initialState: [],
    fetchFn: async () => DivisionServices.index(),
  });
  const [roles] = useFetch({
    initialState: [],
    fetchFn: async () => RoleServices.index(),
  });
  const isMounted = useMountedState();
  const handleEdit = async ({ selectedRoles, ...payload }, { setSubmitting }) => {
    try {
      const {
        data: { data: fetchedUser },
      } = await UserServices.update({
        id,
        ...payload,
        role_ids: selectedRoles.map(({ id: roleId }) => roleId),
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setUser(fetchedUser);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Users Edit")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <Formik
                enableReinitialize
                initialValues={{
                  email: user.email || "",
                  phone: user.phone || "",
                  name: user.name || "",
                  division_id: divisions.length > 0 ? user.division_id || "" : "",
                  selectedRoles:
                    roles.length > 0 && user.roles?.length > 0
                      ? roles.filter(
                          ({ id: roleId }) => !!user.roles.find(({ id: role }) => roleId === role)
                        ) || []
                      : [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleEdit}
              >
                {({ isValid, touched, errors, submitForm, isSubmitting, resetForm }) => (
                  <Form>
                    <MDBox mb={4}>
                      <Field
                        component={TextField}
                        name="name"
                        type="text"
                        label={t("Name")}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <Field
                        component={TextField}
                        name="email"
                        type="text"
                        label={t("Email")}
                        variant="standard"
                        fullWidth
                        required
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <Field
                        component={TextField}
                        name="phone"
                        type="text"
                        label={t("Phone")}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <FormControl fullWidth>
                        <Field
                          component={TextField}
                          id="division_id"
                          name="division_id"
                          select
                          variant="standard"
                          label={t("Division")}
                        >
                          {divisions.map(({ name, id: divisionId }) => (
                            <MenuItem value={divisionId} key={divisionId}>
                              {name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={4}>
                      <FormControl fullWidth>
                        <Field
                          component={Autocomplete}
                          id="selectedRoles"
                          name="selectedRoles"
                          options={roles}
                          isOptionEqualToValue={({ id: optId }, { id: valId }) => valId === optId}
                          getOptionLabel={({ name }) => name}
                          multiple
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              name="selectedRoles"
                              error={touched.selectedRoles && !!errors.selectedRoles}
                              helperText={touched.selectedRoles && errors.selectedRoles}
                              label={t("Roles")}
                              required
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </MDBox>
                    <MDBox mt={4} mb={1} sx={{ justifyContent: "space-between", display: "flex" }}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={resetForm}
                      >
                        {t("Reset")}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        startIcon={
                          isSubmitting ? <CircularProgress size={20} color="white" /> : <SaveIcon />
                        }
                        disabled={isSubmitting || !isValid}
                        onClick={submitForm}
                      >
                        {t("Save")}
                      </MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </MDBox>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Users Change Password")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <Formik
                initialValues={{
                  password: "",
                  password_confirmation: "",
                }}
                validationSchema={validationPwSchema}
                onSubmit={handleEdit}
              >
                {({ isValid, submitForm, isSubmitting, resetForm }) => (
                  <Form>
                    <MDBox mb={4}>
                      <Field
                        component={TextField}
                        name="password"
                        type="password"
                        required
                        label={t("Password")}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <Field
                        component={TextField}
                        name="password_confirmation"
                        required
                        type="password"
                        label={t("Password Confirmation")}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1} sx={{ justifyContent: "space-between", display: "flex" }}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={resetForm}
                      >
                        {t("Reset")}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        startIcon={
                          isSubmitting ? <CircularProgress size={20} color="white" /> : <SaveIcon />
                        }
                        disabled={isSubmitting || !isValid}
                        onClick={submitForm}
                      >
                        {t("Save")}
                      </MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
