import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "formik-mui-x-date-pickers";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import ProjectServices from "../../../../services/ProjectServices";
import { processError } from "../../../../utils/services/api";
import useFetch from "../../../../utils/hooks/useFetch";
import EmployeeServices from "../../../../services/EmployeeServices";
import ClientServices from "../../../../services/ClientServices";
import UserServices from "../../../../services/UserServices";
import MyLocalizationProvider from "../../../../components/MyLocalizationProvider";

// eslint-disable-next-line react/prop-types
export default function ProjectSingleEdit({ prefix = "" }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const validationSchema = yup.object({
    name: yup.string().max(50).required(),
    order_code: yup.string().min(4, t("Min 4")).max(12, t("Max 12")).required(),
    ordered_at: yup.date().required(),
    designed_at: yup.date(),
    produced_at: yup.date(),
    constructed_at: yup.date(),
    memo: yup.string().max(1000),
    employee_id: yup.number().positive().required(),
    assigned_to_id: yup.number().positive().required(),
    is_completed: yup.boolean(),
  });
  const { id } = useParams();
  const [project, setProject] = useFetch({
    fetchFn: async () => ProjectServices.detail({ id }),
    deps: [id],
  });
  const [client, setClient] = useState("");
  const handleChangeClient = ({ target: { value } }) => {
    setClient(value);
  };
  useEffect(() => {
    setClient(project?.employee?.client_id || "");
  }, [project]);
  const [employees] = useFetch({
    initialState: [],
    fetchFn: async () => (Number.isInteger(client) ? EmployeeServices.index({ client }) : []),
    deps: [client],
  });
  const [clients] = useFetch({
    initialState: [],
    fetchFn: async () => ClientServices.index(),
  });
  const [users] = useFetch({
    initialState: [],
    fetchFn: async () => UserServices.index(),
  });
  const navigate = useNavigate();
  const handleEdit = async (payload, { setSubmitting }) => {
    try {
      const {
        data: { data: fetchedProject },
      } = await ProjectServices.update({
        id,
        ...payload,
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setProject(fetchedProject);
      navigate(`${prefix}/projects`);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MyLocalizationProvider>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("Edit Project")}
                </MDTypography>
              </MDBox>
              <Formik
                enableReinitialize
                initialValues={{
                  name: project.name || "",
                  order_code: project.order_code || "",
                  ordered_at: parseISO(project.ordered_at) || new Date(),
                  designed_at: parseISO(project.designed_at) || new Date(),
                  produced_at: parseISO(project.produced_at) || new Date(),
                  constructed_at: parseISO(project.constructed_at) || new Date(),
                  memo: project.memo || "",
                  employee_id: employees.length > 0 ? project.employee_id || "" : "",
                  assigned_to_id: users.length > 0 ? project.assigned_to_id || "" : "",
                  is_completed: project.is_completed || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleEdit}
              >
                {({ isValid, submitForm, isSubmitting, resetForm }) => (
                  <Form>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} px={3}>
                          <MDBox p={3} sx={{ border: 1, borderRadius: 4 }}>
                            <MDBox mb={4}>
                              <FormControl fullWidth>
                                <InputLabel id="client_id-lbl" required>
                                  {t("Project Client")}
                                </InputLabel>
                                <Select
                                  labelId="client_id-lbl"
                                  id="client_id"
                                  value={client}
                                  label={t("Project Client")}
                                  onChange={handleChangeClient}
                                  required
                                >
                                  {clients.map(({ name, id: cId }) => (
                                    <MenuItem value={cId} key={cId}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {t("Current Value:")} {project?.employee?.client?.name}
                              </FormControl>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={TextField}
                                name="name"
                                type="text"
                                label={t("Project Name")}
                                variant="standard"
                                required
                                fullWidth
                              />
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={TextField}
                                name="order_code"
                                type="text"
                                inputProps={{ maxLength: 12 }}
                                label={t("Project Order Code")}
                                variant="standard"
                                required
                                fullWidth
                              />
                            </MDBox>
                            <MDBox mb={4}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="employee_id"
                                  name="employee_id"
                                  select
                                  variant="standard"
                                  label={t("Project Employee")}
                                  disabled={client === ""}
                                  required
                                >
                                  {employees.map(({ name, id: eId }) => (
                                    <MenuItem value={eId} key={eId}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Field>
                                {t("Current Value:")} {project?.employee?.name}
                              </FormControl>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={DatePicker}
                                name="ordered_at"
                                label={t("Project Ordered At")}
                                required
                              />
                              <p>
                                {t("Current Value:")}
                                {project?.ordered_at &&
                                  format(parseISO(project.ordered_at), "yyyy-MM-dd")}
                              </p>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={TextField}
                                name="memo"
                                multiline
                                minRows={3}
                                maxRows={10}
                                type="text"
                                label={t("Project Memo")}
                                variant="standard"
                                fullWidth
                              />
                            </MDBox>
                          </MDBox>
                        </Grid>
                        <Grid item md={6} px={3}>
                          <MDBox p={3} sx={{ border: 1, borderRadius: 4 }}>
                            <MDBox mb={4}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  id="assigned_to_id"
                                  name="assigned_to_id"
                                  select
                                  variant="standard"
                                  label={t("Project Person In Charge")}
                                  required
                                >
                                  {users.map(({ name, id: uId }) => (
                                    <MenuItem value={uId} key={uId}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Field>
                                {t("Current Value:")} {project?.assigned_to?.name}
                              </FormControl>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={DatePicker}
                                name="designed_at"
                                label={t("Project Designed At")}
                              />
                              <p>
                                {t("Current Value:")}
                                {project?.designed_at &&
                                  format(parseISO(project.designed_at), "yyyy-MM-dd")}
                              </p>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={DatePicker}
                                name="produced_at"
                                label={t("Project Produced At")}
                              />
                              <p>
                                {t("Current Value:")}
                                {project?.produced_at &&
                                  format(parseISO(project.produced_at), "yyyy-MM-dd")}
                              </p>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={DatePicker}
                                name="constructed_at"
                                label={t("Project Constructed At")}
                              />
                              <p>
                                {t("Current Value:")}
                                {project?.constructed_at &&
                                  format(parseISO(project.constructed_at), "yyyy-MM-dd")}
                              </p>
                            </MDBox>
                            <MDBox mb={4}>
                              <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="is_completed"
                                Label={{ label: t("Project Is Completed?") }}
                              />
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <MDBox
                        mt={4}
                        mb={1}
                        mr={2}
                        sx={{ justifyContent: "center", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{ ml: 2 }}
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </CardContent>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MyLocalizationProvider>
  );
}
