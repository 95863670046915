/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Profile from "layouts/profile";
// @mui icons
import Icon from "@mui/material/Icon";
import BuildIcon from "@mui/icons-material/Build";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ConstructionIcon from "@mui/icons-material/Construction";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import EngineeringIcon from "@mui/icons-material/Engineering";
import IosShareIcon from "@mui/icons-material/IosShare";
import ProjectList from "./layouts/projects/List";
import ProjectDrawing from "./layouts/projects/List/ProjectDrawing";
import ProjectSingleEdit from "./layouts/projects/Single/Edit";
import ProjectSingleNew from "./layouts/projects/Single/New";
import DrawingSingleEdit from "./layouts/drawings/Single/Edit";
import DrawingSingleNew from "./layouts/drawings/Single/New";
import RevisionSingleEdit from "./layouts/revisions/RevisionSingleEdit";
import ProducingList from "./layouts/stages/producing/List";
import TreatingList from "./layouts/stages/treating/List";
import FactoringList from "./layouts/stages/factoring/List";
import ArrivalList from "./layouts/stages/arrival/List";
import SiteList from "./layouts/stages/site/List";
import ConstructingList from "./layouts/stages/constructing/List";
import ShippingList from "./layouts/stages/shipping/List";
import BulkCreate from "./layouts/projects/List/BulkCreate";

const routes = [
  {
    route: "/projects/:id/edit",
    exact: false,
    component: <ProjectSingleEdit />,
    key: "projects-edit",
    object: "Project",
    operator: "update",
  },
  {
    route: "/projects/new",
    component: <ProjectSingleNew />,
    key: "projects-new",
    object: "Project",
    operator: "create",
  },
  {
    route: "/projects/:id/bulkCreate",
    component: <BulkCreate />,
    key: "bulk-create-drawing",
    object: "Drawing",
    operator: "create",
  },
  {
    route: "/projects/:id",
    exact: false,
    component: <ProjectSingleEdit />,
    key: "projects-show",
    object: "Project",
    operator: "view",
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <AccountTreeIcon />,
    route: "/projects",
    component: <ProjectList />,
    object: "Project",
    operator: "viewAny",
  },
  {
    name: "Drawings",
    key: "drawings",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/projects/:project_id/drawings",
    component: <ProjectDrawing />,
    object: "Drawing",
    operator: "viewAny",
  },
  {
    route: "/drawings/:id",
    exact: false,
    component: <DrawingSingleEdit />,
    key: "drawings-show",
    object: "Drawing",
    operator: "view",
  },
  {
    route: "/drawings/:drawing_id/revisions/:id/edit",
    exact: false,
    component: <RevisionSingleEdit />,
    key: "revisions-edit",
    object: "DrawingRevision",
    operator: "update",
  },
  {
    route: "/drawings/:drawing_id/revisions/:id",
    exact: false,
    component: <RevisionSingleEdit />,
    key: "revisions-edit",
    object: "DrawingRevision",
    operator: "update",
  },
  {
    route: "/projects/:project_id/drawings/:id/edit",
    exact: false,
    component: <DrawingSingleEdit />,
    key: "drawings-edit",
    object: "Drawing",
    operator: "update",
  },
  {
    route: "/projects/:project_id/drawings/new",
    component: <DrawingSingleNew />,
    key: "drawing-new",
    object: "Drawing",
    operator: "create",
  },
  {
    type: "collapse",
    name: "Piece Producing Manager",
    key: "producings-stage",
    icon: <BuildIcon />,
    route: "/producings-stage",
    component: <ProducingList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Factory Inspection",
    key: "factoring-stage",
    icon: <PrecisionManufacturingIcon />,
    route: "/factoring-stage",
    component: <FactoringList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Treatment Manager",
    key: "treating-stage",
    icon: <EngineeringIcon />,
    route: "/treating-stage",
    component: <TreatingList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Shipping Registration",
    key: "shipping-stage",
    icon: <IosShareIcon />,
    route: "/shipping-stage",
    component: <ShippingList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Arrival Check",
    key: "arriving-stage",
    icon: <LocalShippingIcon />,
    route: "/arriving-stage",
    component: <ArrivalList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Site Inspection",
    key: "site-stage",
    icon: <WarehouseIcon />,
    route: "/site-stage",
    component: <SiteList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Piece Construction Progress",
    key: "constructing-stage",
    icon: <ConstructionIcon />,
    route: "/constructing-stage",
    component: <ConstructingList />,
    object: "PieceProgress",
    operator: "viewAny",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
