import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MDButton from "../../MDButton";
import Modal from "./modal";

function OperationsButton({ drawing }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MDButton color="info" onClick={handleOpen}>
        {t("Business Operators")}
      </MDButton>
      {open && (
        <Dialog fullScreen open={open} onClose={handleClose}>
          <DialogTitle>
            {t("Business Operators")}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Modal drawing={drawing} />
        </Dialog>
      )}
    </>
  );
}
OperationsButton.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  drawing: PropTypes.object.isRequired,
};

export default OperationsButton;
