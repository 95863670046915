import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { Checkbox } from "@mui/material";
import PieceServices from "../../../../services/PieceServices";
import DataTable from "../../../../examples/Tables/DataTable";

// eslint-disable-next-line no-unused-vars
function PiecesView({ isSubmitting, handleChange, drawingId, checkedIds = [] }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy, filters } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await PieceServices.index({ drawingId, page: pageIndex + 1, pageSize, sortBy, filters });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(data);
    setLoading(false);
  };

  const columns = [
    { Header: t("Piece Name"), accessor: "name", align: "left" },
    {
      Header: t("Piece To Delete"),
      disableSortBy: true,
      accessor: "id",
      align: "center",
      // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
      Cell: ({ value }) => (
        <Checkbox
          checked={checkedIds.includes(value)}
          onChange={({ target: { checked } }) => handleChange({ value, checked })}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <DataTable
      table={{ columns, rows }}
      manualPagination
      onFetchData={handleFetchData}
      pageCount={pageCount}
      skipPageReset={skipPageResetRef.current}
      loading={loading}
      total={total}
    />
  );
}
PiecesView.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  drawingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checkedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

PiecesView.defaultProps = {};

export default PiecesView;
