import PropTypes from "prop-types";
import { Chip, CircularProgress, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { differenceInDays, getTime, parseISO } from "date-fns";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import StageLog from "./StageLog";
import useFetch from "../../../utils/hooks/useFetch";
import StageTypeServices from "../../../services/StageTypeServices";
import PieceProgressServices from "../../../services/PieceProgressServices";

const COLORS = ["#008FFB", "#00E396", "#775DD0", "#FEB019", "#FF4560", "#FF2340"];
function PieceModal({ onClose, piece: { id, name, revision }, open }) {
  const { t } = useTranslation();
  const [stageTypes] = useFetch({
    fetchFn: async () => StageTypeServices.index(),
    deps: [id],
    initialState: [],
  });
  const [progresses] = useFetch({
    fetchFn: async () => PieceProgressServices.index({ pieceId: id }),
    initialState: [],
    deps: [id],
  });
  const seriesData = stageTypes
    .map(({ name: typeName, final_stage: stage }, idx) => {
      const final = progresses.find(({ stage: { id: stageId } = {} }) => stageId === stage.id);
      if (final) {
        return {
          x: t(typeName),
          y: [getTime(parseISO(final.created_at)), getTime(parseISO(final.updated_at))],
          fillColor: COLORS[idx],
        };
      }
      return null;
    })
    .filter(Boolean);
  const chart = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter([a, b], opts) {
          const label = opts.w.globals.labels[opts.dataPointIndex];
          const diff = differenceInDays(b, a);
          return `${label}: ${diff}${diff > 1 ? t(" days") : t(" day")}`;
        },
        style: {
          colors: ["#f3f4f5", "#fff"],
        },
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          colors: ["#f3f4f5", "#fff"],
          opacity: 1,
        },
      },
    },
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent>
        <MDTypography variant="h3" mb={2}>
          {name}
        </MDTypography>
        {stageTypes.length === 0 && <CircularProgress size={20} mb={3} />}
        <Grid container spacing={3} mb={3}>
          {stageTypes.map((type) => (
            <Grid item xs={12} md={4} key={type.id}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StageLog
                  progress={progresses.find(
                    // eslint-disable-next-line camelcase
                    ({ stage: { type_id } = {} }) => type_id === type.id
                  )}
                  type={type}
                >
                  {({ onClick }) => (
                    <MDButton variant="text" color="primary" onClick={onClick}>
                      <MDTypography variant="h4" color="primary">
                        {t(type.name)}
                      </MDTypography>
                    </MDButton>
                  )}
                </StageLog>
                {progresses.find(
                  ({ stage: { id: stageId } = {} }) => stageId === type.final_stage.id
                ) ? (
                  <Chip
                    label={t("Completed")}
                    color="success"
                    variant="filled"
                    icon={<CheckBoxIcon />}
                  />
                ) : (
                  <Chip
                    label={t("Uncompleted")}
                    color="error"
                    variant="outlined"
                    icon={<DoNotDisturbOnIcon />}
                  />
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
        {revision && (
          <MDTypography variant="h3" mb={2}>
            {revision?.drawing?.project?.name}
          </MDTypography>
        )}
        {/* <ChildModal /> */}
        {seriesData.length > 0 && (
          <MDBox sx={{ border: 1 }} mb={3}>
            <Chart options={chart.options} series={chart.series} type="rangeBar" height="350" />
          </MDBox>
        )}
        <MDBox display="flex" justifyContent="center">
          <MDButton color="info" onClick={onClose}>
            {t("Close")}
          </MDButton>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}

PieceModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  piece: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PieceModal;
