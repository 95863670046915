import PropTypes from "prop-types";
import { useState } from "react";
import TableViewIcon from "@mui/icons-material/TableView";
import { useTranslation } from "react-i18next";
import MDButton from "../../../components/MDButton";
import PieceModal from "./PieceModal";

function PieceInfoButton({ piece, title, color, variant }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MDButton
        color={color}
        variant={variant}
        onClick={handleOpen}
        startIcon={!title && <TableViewIcon />}
      >
        {title || t("Detail")}
      </MDButton>
      {open && <PieceModal onOpen={handleOpen} onClose={handleClose} piece={piece} open={open} />}
    </>
  );
}

PieceInfoButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  piece: PropTypes.object.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
};
PieceInfoButton.defaultProps = {
  title: null,
  color: "info",
  variant: "contained",
};

export default PieceInfoButton;
