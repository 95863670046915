import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import Select from "@mui/material/Select";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import ProjectServices from "../../../services/ProjectServices";

/* eslint-disable react/no-unstable-nested-components, react/prop-types, camelcase */
export default function PmList() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({
    pageIndex = 1,
    pageSize = 50,
    sortBy,
    globalFilter,
    filters,
  } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const mappedFilters = filters.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await ProjectServices.index({
      page: pageIndex + 1,
      pageSize,
      sortBy,
      globalFilter,
      ...mappedFilters,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Project Name"),
      accessor: "name",
      align: "left",
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }) => (
        <Link
          component={RouterLink}
          to={`/project_manager/projects/${id}/drawings`}
          color="primary"
        >
          {value}
        </Link>
      ),
    },
    {
      Header: t("Drawing Count"),
      id: "drawing_count",
      accessor: "drawing_count",
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("In-house Work"),
      id: "in_house_count",
      accessor: "in_house_count",
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("Shipping"),
      id: "shipping_count",
      accessor: "shipping_count",
      align: "right",
    },
    {
      Header: t("Acceptance"),
      id: "acceptance_count",
      accessor: "acceptance_count",
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("Coverage"),
      id: "coverage_count",
      accessor: "coverage_count",
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("Status"),
      accessor: "is_completed",
      align: "center",
      Filter: ({ column: { setFilter, filterValue = -1 } }) => (
        <FormControl fullWidth>
          <Select
            value={filterValue}
            onChange={({ target: { value } }) => {
              setFilter(value);
            }}
          >
            <MenuItem value={2}>{t("All")}</MenuItem>
            <MenuItem value={1}>{t("Completed")}</MenuItem>
            <MenuItem value={3}>{t("Uncompleted")}</MenuItem>
          </Select>
        </FormControl>
      ),
      Cell: ({ value }) => (value ? t("Completed") : t("Uncompleted")),
    },
    {
      Header: t("Project Constructed At"),
      id: "constructed_at",
      sortDescFirst: true,
      accessor: ({ constructed_at }) => format(parseISO(constructed_at), "yyy-MM-dd"),
      align: "left",
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Projects Index")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                manualFilters
                manualGlobalFilter
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
                canSearch
                initialFilters={[{ id: "is_completed", value: 3 }]}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
