import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MDButton from "../../MDButton";

// eslint-disable-next-line camelcase
function ProgressStack({ stages, progresses, submitStage: { stage_id }, setVals }) {
  const { i18n } = useTranslation();
  // eslint-disable-next-line camelcase
  const defaultId = stage_id || progresses[0]?.stage_id;
  const [selected, setSelected] = useState();
  useEffect(() => {
    setSelected(defaultId);
  }, [defaultId]);
  // eslint-disable-next-line camelcase
  return (
    <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" spacing={2}>
      {stages.map(({ name, id, ...stage }) => (
        <MDButton
          variant={selected === id ? "contained" : "outlined"}
          // todo reflect selected value into button status
          key={id}
          color="primary"
          onClick={() => {
            setSelected(id);
            setVals({ col: "stage_id", newVal: id });
          }}
        >
          {stage[`title_${i18n.language}`] || stage.title_en}
        </MDButton>
      ))}
    </Stack>
  );
}
ProgressStack.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  progresses: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  stages: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  submitStage: PropTypes.object,
  setVals: PropTypes.func,
};

ProgressStack.defaultProps = {
  progresses: [],
  stages: [],
  submitStage: {},
  setVals: () => {},
};

export default ProgressStack;
