import { CircularProgress, Dialog, Paper, Stack } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import HistoryIcon from "@mui/icons-material/History";
import { Trans, useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import useFetch from "../../../utils/hooks/useFetch";
import PieceProgressServices from "../../../services/PieceProgressServices";
import TruncatedPopup from "../../../components/TruncatedPopup";

function PieceProgressLog({ id, open, handleClose, type }) {
  const { t, i18n } = useTranslation();
  const [{ audits = [] } = {}] = useFetch({
    fetchFn: async () => PieceProgressServices.detail({ id }),
    deps: [id],
  });
  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={1}>
          <HistoryIcon fontSize="large" />
          <MDTypography variant="h4">
            <Trans i18nKey="stage_history">{{ object: t(type.name) }} history</Trans>
          </MDTypography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers p={2}>
        {audits.length === 0 && <CircularProgress size={20} mb={3} />}
        {/* eslint-disable camelcase */}
        {audits.map(
          ({
            id: auditId,
            new_values: { memo, set_at, steps = [] } = {},
            assigner,
            stage,
            created_at: createdAt,
          }) => (
            <Paper elevation={3} key={auditId} sx={{ p: 2, mb: 1, mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item md={2}>
                  {format(parseISO(createdAt), "yyy/MM/dd")}
                </Grid>
                <Grid item>
                  {stage && (stage[`title_${i18n.language}`] || stage.title_en)}
                  {steps
                    ?.filter(({ pivot: { is_active } }) => is_active)
                    ?.map((step) => step[`title_${i18n.language}`] || step.title_en)
                    ?.join(", ")}
                </Grid>
                <Grid item md={2}>
                  {assigner}
                  {set_at && format(parseISO(set_at), t("date_format"))}
                </Grid>
                <Grid item>{memo && <TruncatedPopup text={memo} />}</Grid>
              </Grid>
            </Paper>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <MDButton variant="gradient" color="info" onClick={handleClose}>
          {t("Close")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
PieceProgressLog.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PieceProgressLog;
