import { Outlet } from "react-router-dom";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

function UserLayout() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Outlet />
    </DashboardLayout>
  );
}

export default UserLayout;
