import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Chip, CircularProgress, Paper, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import { useState } from "react";
import { useSanctum } from "react-sanctum";
import Link from "@mui/material/Link";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import useFetch from "../../utils/hooks/useFetch";
import RevisionServices from "../../services/RevisionServices";
import Lightbox from "../../components/Lightbox";
import RevisionList from "./components/RevisionList";
import PieceList from "./components/PieceList";
import MDButton from "../../components/MDButton";
import { processError } from "../../utils/services/api";
import PieceGraphs from "./components/PieceGraphs";
import SubmitWithConfirmBtn from "../../components/SubmitWithConfirmBtn";
import DrawingServices from "../../services/DrawingServices";
import { isAllowed } from "../../utils/config";
import QRModalButton from "../../components/Drawing/QRModalButton";

// eslint-disable-next-line react/prop-types
export default function RevisionSingleEdit({ prefix = "" }) {
  const { t } = useTranslation();
  const { id: revId } = useParams();
  const [revision, setRevision] = useFetch({
    fetchFn: async () =>
      RevisionServices.detail({
        id: revId,
      }),
    deps: [revId],
  });

  const isMounted = useMountedState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleReset = async () => {
    setLoading(true);
    try {
      await DrawingServices.reset({ id: revision.drawing_id });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setLoading(false);
      navigate(`${prefix}/projects/${revision.drawing.project_id}/drawings`);
    } catch (error) {
      processError(error);
      setLoading(false);
    }
  };
  const { user, authenticated } = useSanctum();
  const handleChange = async (payload) => {
    setLoading(true);
    try {
      const {
        data: { data: rev },
      } = await RevisionServices.update({ id: revId, ...payload });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setLoading(false);
      setRevision(rev);
    } catch (error) {
      processError(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Stack direction={{ xs: "column", md: "row" }} mb={3} spacing={1} justifyContent="flex-end">
        {revision?.drawing &&
          isAllowed({ object: "DrawingRevision", operator: "update", user, authenticated }) && (
            <MDButton
              variant="gradient"
              color="warning"
              startIcon={<EditIcon />}
              href={`${prefix}/projects/${revision.drawing.project_id}/drawings/${revision.drawing_id}/edit`}
            >
              {t("Edit Drawing")}
            </MDButton>
          )}
        {revision.drawing && (
          <QRModalButton
            drawing={revision.drawing}
            btnTitle="Print QR Code"
            variant="gradient"
            color="success"
            sx={{}}
          />
        )}
        {isAllowed({ object: "DrawingRevision", operator: "update", user, authenticated }) &&
          (revision?.current_pieces_count || 0) > 0 &&
          (revision.completed_at ? (
            <SubmitWithConfirmBtn
              isSubmitting={loading}
              isValid
              handleSubmit={() => handleChange({ completed_at: null })}
              message="Confirm change drawing?"
              title="Confirm change drawing?"
              submitText="Submit"
              btnText="Unconfirm Drawing"
              normalIcon={<CancelScheduleSendIcon />}
              loadingIcon={<CircularProgress size={20} />}
            />
          ) : (
            <SubmitWithConfirmBtn
              isSubmitting={loading}
              isValid
              handleSubmit={() => handleChange({ completed_at: format(Date.now(), "y-M-d") })}
              message="Confirm change drawing?"
              title="Confirm change drawing?"
              btnText="Confirm Drawing"
              submitText="Submit"
              normalIcon={<SendIcon />}
              loadingIcon={<CircularProgress size={20} />}
              variant="outlined"
              btnColor="primary"
            />
          ))}
        {revision &&
          isAllowed({ object: "DrawingRevision", operator: "update", user, authenticated }) &&
          (revision.approved_at ? (
            <SubmitWithConfirmBtn
              isSubmitting={loading}
              isValid
              handleSubmit={() => handleChange({ approved_at: null })}
              message="Confirm change drawing?"
              title="Confirm change drawing?"
              submitText="Submit"
              btnText="Disapprove Drawing"
              normalIcon={<BookmarkRemoveIcon />}
              loadingIcon={<CircularProgress size={20} />}
            />
          ) : (
            <SubmitWithConfirmBtn
              isSubmitting={loading}
              isValid
              handleSubmit={() => handleChange({ approved_at: format(Date.now(), "y-M-d") })}
              message="Confirm change drawing?"
              title="Confirm change drawing?"
              submitText="Submit"
              btnText="Approve Drawing"
              normalIcon={<BookmarkAddedIcon />}
              loadingIcon={<CircularProgress size={20} />}
              variant="outlined"
              btnColor="error"
            />
          ))}
      </Stack>
      <PieceGraphs revision={revision} />
      <Grid container spacing={3} mb={3}>
        {revision && (
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDBox>
                  <MDTypography variant="h4">{t("Drawing Info")}</MDTypography>
                  {revision?.drawing && (
                    <MDTypography variant="h6">
                      <Link href={`${prefix}/projects/${revision.drawing.project_id}/drawings`}>
                        <MDTypography variant="p" color="info">
                          {revision.drawing.project.name}
                        </MDTypography>
                      </Link>
                      -{revision.drawing.name}
                    </MDTypography>
                  )}
                  <MDTypography variant="h6">
                    {t("Revision Number")} {revision?.rev_number}
                  </MDTypography>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1} mb={2}>
                    <Chip
                      label={revision?.approved_at ? t("Confirmed") : t("Unconfirmed")}
                      color="primary"
                      variant={revision?.approved_at ? "filled" : "outlined"}
                    />
                    <Chip
                      label={revision?.completed_at ? t("Completed") : t("Uncompleted")}
                      color="info"
                      variant={revision?.completed_at ? "filled" : "outlined"}
                    />
                  </Stack>
                  <MDTypography variant="h6">
                    {t("Approved At")}:{" "}
                    {revision?.approved_at
                      ? format(parseISO(revision.approved_at), t("date_format"))
                      : "-"}
                  </MDTypography>
                </MDBox>
                <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
                  <MDTypography variant="h4">{t("Piece Total")}</MDTypography>
                  <MDTypography variant="h2">
                    {revision?.current_pieces_count ? revision.current_pieces_count : "-"}
                  </MDTypography>
                </Paper>
              </MDBox>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Card>
            <MDBox p={3}>
              <Stack direction="row" justifyContent="space-between">
                <MDTypography variant="h3">{t("Drawing Revision History")}</MDTypography>
                {revision &&
                  isAllowed({ object: "Drawing", operator: "update", user, authenticated }) && (
                    <SubmitWithConfirmBtn
                      isSubmitting={loading}
                      isValid
                      btnColor="warning"
                      handleSubmit={handleReset}
                      message="Confirm reset drawing?"
                      title="Confirm reset drawing?"
                      submitText="Reset"
                      btnText="Reset"
                      normalIcon={<RestartAltIcon />}
                      loadingIcon={<CircularProgress size={20} />}
                    />
                  )}
              </Stack>
              {revision?.drawing_id && <RevisionList drawingId={revision.drawing_id} />}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h3">{t("Pieces Details")}</MDTypography>
              {(revision?.current_pieces_count || 0) > 0 && <PieceList revisionId={revision.id} />}
              {revision?.drawing &&
                isAllowed({ object: "Drawing", operator: "update", user, authenticated }) && (
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      variant="gradient"
                      color="warning"
                      startIcon={<EditIcon />}
                      href={`${prefix}/projects/${revision.drawing.project_id}/drawings/${revision.drawing_id}/edit`}
                    >
                      {t("Edit Drawing")}
                    </MDButton>
                  </MDBox>
                )}
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h3" mb={2}>
                {t("Image of Drawing")}
              </MDTypography>
              {revision?.image && <Lightbox image={revision.image} />}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <MDTypography variant="h3">{t("Memo")}</MDTypography>
          {revision?.memo}
        </CardContent>
      </Card>
    </>
  );
}
