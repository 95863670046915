import { Outlet } from "react-router-dom";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDBox from "../MDBox";

function EmptyLayout() {
  return (
    <PageLayout>
      <MDBox
        width="100%"
        height="100vh"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Outlet />
      </MDBox>
    </PageLayout>
  );
}

export default EmptyLayout;
