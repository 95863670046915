import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index(params) {
    return authClient.get("/api/step-templates", { params });
  },
  async create(payload) {
    return authClient.post(`/api/step-templates`, payload);
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/step-templates/${id}`, payload);
  },
  async destroy({ id }) {
    return authClient.delete(`/api/step-templates/${id}`);
  },
  async detail({ id }) {
    return authClient.get(`/api/step-templates/${id}`);
  },
};
