import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index(params) {
    return authClient.get("/api/projects", { params });
  },
  async create(payload) {
    return authClient.post(`/api/projects`, payload);
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/projects/${id}`, payload);
  },
  async destroy({ id }) {
    return authClient.delete(`/api/projects/${id}`);
  },
  async detail({ id, params }) {
    return authClient.get(`/api/projects/${id}`, { params });
  },
};
