import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async update(payload) {
    return authClient.put(`/api/profile`, payload);
  },
  async updatePassword(payload) {
    return authClient.put(`/api/profile/password`, payload);
  },
  async detail() {
    return authClient.get(`/api/profile`);
  },
};
