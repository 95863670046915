import { useTimeout } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import SignInTemplate from "../SignInTemplate";

function MyOutlet(props) {
  const [isReady] = useTimeout(2500);
  return isReady() ? <SignInTemplate {...props} /> : <CircularProgress />;
}

export default MyOutlet;
