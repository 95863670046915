import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { useSanctum } from "react-sanctum";
import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import { useMountedState } from "react-use";
import { noop } from "lodash";
import MultipleCheckForm from "./MultipleCheckForm";
import MDTypography from "../../MDTypography";
import PieceProgressesForm from "./PieceProgressesForm";
import useFetch from "../../../utils/hooks/useFetch";
import UserServices from "../../../services/UserServices";
import StageServices from "../../../services/StageServices";
import StepTemplateServices from "../../../services/StepTemplateServices";
import DrawingDefaultStepServices from "../../../services/DrawingDefaultStepServices";

function ProgressContent({ type, drawing, afterSubmit, stackForm, piecesForm, ...props }) {
  const { t } = useTranslation();
  const { user } = useSanctum();
  const [users] = useFetch({
    initialState: [],
    fetchFn: async () => UserServices.index(),
  });
  const [stages] = useFetch({
    fetchFn: async () => StageServices.index({ type_name: type }),
    initialState: [],
    deps: [type],
  });
  const [incremental, setIncremental] = useState(0);
  const isMounted = useMountedState();
  const [steps] = useFetch({
    fetchFn: async () => StepTemplateServices.index({ type_name: type }),
    initialState: [],
    deps: [type],
  });
  const { latest_revision: revision } = drawing;
  const [templateSteps] = useFetch({
    initialState: [],
    fetchFn: async () =>
      DrawingDefaultStepServices.index({ drawingId: revision.drawing_id, type_name: type }),
  });
  const mappedSteps = useMemo(
    () => templateSteps.map(({ id: temp }) => temp),
    [JSON.stringify(templateSteps)]
  );
  const StackForm = stackForm;
  const PiecesForm = piecesForm;
  return (
    <DialogContent>
      {stages.length === 0 && <CircularProgress />}
      {stages.length > 0 && (
        <StackForm
          type={type}
          stages={stages}
          users={users}
          user={user}
          drawing={drawing}
          templateSteps={mappedSteps}
          steps={steps}
          afterSubmit={() => {
            afterSubmit();
            if (!isMounted) return;
            setIncremental(incremental + 1);
          }}
        />
      )}
      <MDTypography variant="h4">{t("Single Check")}</MDTypography>
      {stages.length > 0 && (
        <PiecesForm
          type={type}
          afterSubmit={afterSubmit}
          object
          stages={stages}
          templateSteps={mappedSteps}
          steps={steps}
          drawing={drawing}
          incr={incremental}
          users={users}
          user={user}
          {...props}
        />
      )}
    </DialogContent>
  );
}
ProgressContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func,
  stackForm: PropTypes.elementType,
  piecesForm: PropTypes.elementType,
};
ProgressContent.defaultProps = {
  afterSubmit: noop,
  stackForm: MultipleCheckForm,
  piecesForm: PieceProgressesForm,
};
export default ProgressContent;
