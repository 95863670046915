import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import useFetch from "../../../utils/hooks/useFetch";
import StageTypeServices from "../../../services/StageTypeServices";

function PieceGraphs({ revision: { current_pieces_count: count = 0, id, stats = [] } = {} }) {
  const { t } = useTranslation();
  const [stageTypes] = useFetch({
    fetchFn: async () => StageTypeServices.index(),
    deps: [id],
    initialState: [],
  });
  if (count === 0 || !id) {
    return null;
  }
  // {/* // borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"], */}
  // {/* borderWidth: 1, */}
  const donutOptions = {
    chart: {
      type: "donut",
    },
    colors: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    labels: [t("Completed"), t("Uncompleted")],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Card sx={{ mb: 3 }}>
      <MDBox p={3}>
        <Grid container spacing={3}>
          {stageTypes.map(({ name }) => (
            <Grid item xs={12} md={4} lg={2} key={name}>
              <MDTypography variant="h5" mb={2} sx={{ textAlign: "center" }}>
                {t(name)}
              </MDTypography>
              <MDBox display="flex" justifyContent="center">
                <Chart
                  options={donutOptions}
                  series={[stats[`${name}.completed`], count - stats[`${name}.completed`]]}
                  type="donut"
                />
              </MDBox>
              <MDTypography variant="h5" sx={{ textAlign: "center" }} mt={2}>
                {stats[`${name}.completed`]} / {count}
              </MDTypography>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );
}
PieceGraphs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  revision: PropTypes.object.isRequired,
};

export default PieceGraphs;
