import PropTypes from "prop-types";
import { useState } from "react";
import PieceProgressLog from "./PieceProgressLog";

function StageLog({ type, children, progress }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {children({ onClick: progress ? handleOpen : () => {} })}
      {progress && open && (
        <PieceProgressLog open={open} handleClose={handleClose} type={type} id={progress.id} />
      )}
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
StageLog.propTypes = {
  progress: PropTypes.object,
  type: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
StageLog.defaultProps = {
  progress: null,
};

export default StageLog;
