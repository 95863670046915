import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index(params) {
    return authClient.get("/api/permission-objects", { params });
  },
  async create(payload) {
    return authClient.post(`/api/permission-objects`, payload);
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/permission-objects/${id}`, payload);
  },
  async destroy({ id }) {
    return authClient.delete(`/api/permission-objects/${id}`);
  },
  async detail({ id }) {
    return authClient.get(`/api/permission-objects/${id}`);
  },
};
