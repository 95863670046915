import { useTranslation } from "react-i18next";
import { useMountedState } from "react-use";
import { useEffect, useRef, useState } from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import DataTable from "../../../examples/Tables/DataTable";
import RevisionServices from "../../../services/RevisionServices";

function RevisionList({ drawingId }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await RevisionServices.index({
      drawingId,
      page: pageIndex + 1,
      pageSize,
      sortBy,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Revision Number"),
      accessor: "rev_number",
      align: "left",
      /* eslint-disable react/no-unstable-nested-components, react/prop-types */
      Cell: ({
        value,
        row: {
          original: { id },
        },
        /* eslint-enable react/prop-types, react/no-unstable-nested-components, react/prop-types */
      }) => (
        <Link
          component={RouterLink}
          to={`/drawings/${drawingId}/revisions/${id}/edit`}
          color="primary"
        >
          {value}
        </Link>
      ),
    },
    {
      Header: t("Created By"),
      // eslint-disable-next-line camelcase
      accessor: ({ created_by }) => {
        // eslint-disable-next-line camelcase
        const { name } = created_by || {};
        return name;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { creator_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/users/${creator_id}/edit`}>
          {value}
        </Link>
      ),
      align: "left",
      disableSortBy: true,
    },
    {
      Header: t("Created At"),
      id: "created_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ created_at }) => format(parseISO(created_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Updated At"),
      id: "updated_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ updated_at }) => format(parseISO(updated_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <DataTable
      table={{ columns, rows }}
      manualPagination
      onFetchData={handleFetchData}
      pageCount={pageCount}
      skipPageReset={skipPageResetRef.current}
      loading={loading}
      total={total}
    />
  );
}

RevisionList.propTypes = {
  drawingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RevisionList;
