import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index(params) {
    return authClient.get("/api/partners", { params });
  },
  async create(payload) {
    return authClient.post(`/api/partners`, payload);
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/partners/${id}`, payload);
  },
  async destroy({ id }) {
    return authClient.delete(`/api/partners/${id}`);
  },
  async detail({ id }) {
    return authClient.get(`/api/partners/${id}`);
  },
};
