import PropTypes from "prop-types";
import { Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import { TextField } from "formik-mui";
import { useMemo } from "react";
import { processError } from "../../../utils/services/api";
import MDButton from "../../MDButton";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import RevisionServices from "../../../services/RevisionServices";
import { PROGRESSES } from "../../../utils/config";
import StepSettingsDialog from "./StepSettingsDialog";

/* eslint-disable camelcase */
function MultipleCheckFormWithStep({ templateSteps, steps, type, drawing, stages, user, users }) {
  const { latest_revision: revision } = drawing;
  const { t, i18n } = useTranslation();
  const validationSchema = yup.object({
    assigned_id: yup.number().positive().required(),
    stage_id: yup.number().positive().required(),
  });
  const navigate = useNavigate();
  const handleEdit = async (payload, { setSubmitting, resetForm }) => {
    try {
      await RevisionServices.update({
        id: revision.id,
        all_pieces: {
          ...payload,
          type: PROGRESSES[type].relations,
          of_type: type,
        },
      });
      toast.success(t("Succeeded"));
      resetForm();
      // afterSubmit(updated);
      navigate(0);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  const progressing = stages.find(({ name }) => name === "progressing");
  const defaultStage = stages.find(({ is_default }) => is_default);
  const handleStepChange = ({ setFieldValue, stage_id, step_ids, id }) => {
    if (!step_ids.includes(id)) {
      setFieldValue("step_ids", [...step_ids, id]);
      if (stage_id !== stages[stages.length - 1].id) {
        setFieldValue("stage_id", progressing.id);
      }
    } else {
      const result = step_ids.filter((v) => v !== id);
      setFieldValue("step_ids", result);
      if (result.length === 0) {
        setFieldValue("stage_id", defaultStage.id);
      } else if (result.length < steps.length) {
        setFieldValue("stage_id", progressing.id);
      }
    }
  };
  const initSteps = useMemo(() => {
    if (drawing.is_bulk) {
      if (templateSteps.length > 0) {
        return steps.filter(({ id }) => templateSteps.includes(id)).map(({ id }) => id);
      }
    } else {
      return steps.filter(({ is_default }) => is_default).map(({ id }) => id);
    }
    return [];
  }, [JSON.stringify(templateSteps), JSON.stringify(steps), JSON.stringify(drawing)]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        assigned_id: users.length > 0 ? user?.id || "" : "",
        stage_id: 0,
        step_ids: [],
        step_settings: initSteps,
        memo: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleEdit}
    >
      {({
        values: { stage_id, step_ids, step_settings },
        isValid,
        submitForm,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" mb={2}>
              <MDTypography variant="h4">{t("Simultaneous check")}</MDTypography>
              <MDBox>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    id="assigned_id"
                    name="assigned_id"
                    select
                    variant="standard"
                    label={t("Assigned To")}
                    required
                  >
                    {users.map(({ name, id: uId }) => (
                      <MenuItem value={uId} key={uId}>
                        {uId === user.id ? t("self") : name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </MDBox>
            </Stack>
            <Stack mb={2} spacing={2} direction={{ xs: "column", md: "row" }}>
              {step_settings.map((id) => (
                <MDButton
                  variant={step_ids.includes(id) ? "contained" : "outlined"}
                  key={id}
                  color="primary"
                  sx={{ minWidth: "100px" }}
                  onClick={() => handleStepChange({ setFieldValue, step_ids, stage_id, id })}
                >
                  {steps.find(({ id: stepId }) => stepId === id)?.[`title_${i18n.language}`] ||
                    steps.find(({ id: stepId }) => stepId === id)?.title_en}
                </MDButton>
              ))}
              {stages
                .filter(({ name, is_default }) => name !== "progressing" && !is_default)
                .map(({ name, id, ...stage }) => (
                  <MDButton
                    variant={stage_id === id ? "contained" : "outlined"}
                    key={id}
                    disabled={step_ids.length !== step_settings.length}
                    color="primary"
                    sx={{ minWidth: "100px" }}
                    onClick={() => {
                      if (stage_id === id && id === stages[stages.length - 1].id) {
                        // OFF value
                        if (step_ids.length > 0) {
                          setFieldValue("stage_id", progressing.id);
                        } else {
                          setFieldValue("stage_id", defaultStage.id);
                        }
                      } else {
                        setFieldValue("stage_id", id);
                      }
                    }}
                  >
                    {stage[`title_${i18n.language}`] || stage.title_en}
                  </MDButton>
                ))}
              <StepSettingsDialog
                drawing={drawing}
                steps={steps}
                // eslint-disable-next-line camelcase
                current={step_settings}
                handleSubmit={(newSteps) => {
                  setFieldValue("step_settings", newSteps);
                  setFieldValue("step_ids", []);
                  setFieldValue("stage_id", defaultStage.id);
                }}
              />
              <Field
                component={TextField}
                name="memo"
                multiline
                minRows={3}
                maxRows={10}
                type="text"
                label={t("Comment")}
                variant="standard"
                fullWidth
              />
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <MDButton
                variant="gradient"
                color="info"
                startIcon={
                  isSubmitting ? <CircularProgress size={20} color="white" /> : <SaveIcon />
                }
                disabled={isSubmitting || !isValid}
                onClick={submitForm}
                sx={{ mx: "auto" }}
              >
                {t("Save")}
              </MDButton>
            </Stack>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}
/* eslint-disable react/forbid-prop-types */
MultipleCheckFormWithStep.propTypes = {
  drawing: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  templateSteps: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

/* eslint-enable camelcase */
export default MultipleCheckFormWithStep;
