import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { useSanctum } from "react-sanctum";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import ItemActions from "../../_common/ItemActions";
import useFetch from "../../../utils/hooks/useFetch";
import ProjectServices from "../../../services/ProjectServices";
import DrawingServices from "../../../services/DrawingServices";
import MDButton from "../../../components/MDButton";
import { revType, isAllowed } from "../../../utils/config";

// eslint-disable-next-line react/prop-types
export default function ProjectDrawing({ prefix = "" }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const { project_id: projectId } = useParams();
  const [project] = useFetch({
    fetchFn: async () =>
      ProjectServices.detail({
        id: projectId,
        params: { loadCounts: ["drawings", "additions", "normals"] },
      }),
  });
  const { user, authenticated } = useSanctum();
  const handleFetchData = async ({
    pageIndex = 1,
    pageSize = 50,
    sortBy,
    globalFilter,
    filters,
  } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const mappedFilters = filters.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await DrawingServices.index({
      projectId,
      page: pageIndex + 1,
      pageSize,
      sortBy,
      globalFilter,
      ...mappedFilters,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    // { Header: t("Id"), accessor: "id", align: "right" },
    {
      Header: t("Drawing Name"),
      accessor: "name",
      align: "left",
      /* eslint-disable react/no-unstable-nested-components, react/prop-types */
      Cell: ({
        value,
        row: {
          original: {
            id: drawingId,
            latest_revision: { id },
          },
        },
        /* eslint-enable react/prop-types, react/no-unstable-nested-components, react/prop-types */
      }) => (
        <Link
          component={RouterLink}
          to={`${prefix}/drawings/${drawingId}/revisions/${id}/edit`}
          color="primary"
        >
          {value}
        </Link>
      ),
    },
    {
      Header: t("Revision Number"),
      /* eslint-disable camelcase */
      accessor: ({ latest_revision }) => {
        const { rev_number } = latest_revision || {};
        return rev_number;
      },
      /* eslint-enable camelcase */
      align: "left",
    },
    {
      Header: t("Assigned To"),
      /* eslint-disable camelcase */
      accessor: ({ assigned_to }) => {
        const { name } = assigned_to || {};
        return name;
      },
      /* eslint-enable camelcase */
      align: "left",
    },
    { Header: t("Piece Total"), accessor: "piece_total", align: "left" },
    { Header: t("Progression"), accessor: "progression", align: "left" },
    {
      Header: t("Approved At"),
      /* eslint-disable camelcase */
      accessor: ({ latest_revision }) => {
        const { approved_at } = latest_revision || {};
        return approved_at;
      },
      Cell: ({ value }) => value && format(parseISO(value), t("date_format")),
      /* eslint-enable camelcase */
      align: "left",
    },
    {
      Header: t("Classification"),
      /* eslint-disable camelcase */
      accessor: ({ latest_revision }) => {
        const { is_additional } = latest_revision || {};
        return t(revType(is_additional));
      },
      id: "is_additional",
      // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
      Filter: ({ column: { setFilter, filterValue = -1 } }) => (
        <FormControl fullWidth>
          <Select
            id="additionalFilter"
            value={filterValue}
            label={t("Classification")}
            onChange={({ target: { value } }) => {
              setFilter(value);
            }}
          >
            <MenuItem value={2}>{t("All")}</MenuItem>
            <MenuItem value={3}>{t("Normal Order")}</MenuItem>
            <MenuItem value={1}>{t("Additional Order")}</MenuItem>
          </Select>
        </FormControl>
      ),
      /* eslint-enable camelcase */
      align: "left",
    },
    {
      Header: t("Created By"),
      /* eslint-disable react/no-unstable-nested-components,react/prop-types,camelcase */
      accessor: ({ created_by }) => {
        const { name } = created_by || {};
        return name;
      },
      align: "left",
      disableSortBy: true,
    },
    {
      Header: t("Created At"),
      id: "created_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ created_at }) => format(parseISO(created_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Updated At"),
      id: "updated_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ updated_at }) => format(parseISO(updated_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
    {
      Header: t("Action"),
      disableSortBy: true,
      accessor: "action",
      align: "center",
      // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
      Cell: ({ value, state: { pageIndex, pageSize, sortBy, filters, globalFilter } }) => (
        <ItemActions
          editLink={`${prefix}/projects/${projectId}/drawings/${value}/edit`}
          key={value}
          object="Drawing"
          onDelete={async () => {
            await DrawingServices.destroy({ id: value });
            await handleFetchData({ pageIndex, pageSize, sortBy, filters, globalFilter });
          }}
        />
      ),
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Card>
            {project?.id && (
              <MDBox p={3}>
                <MDTypography variant="h3">{project?.name}</MDTypography>
                <MDTypography variant="h4">
                  ({project?.employee?.client?.name} : {project?.employee?.name})
                </MDTypography>
                <MDTypography variant="h5">
                  {t("Order assigned to:")} {project?.assigned_to?.name}
                </MDTypography>
                <MDTypography variant="h6">
                  {project?.created_at && format(parseISO(project?.created_at), t("date_format"))}~
                  {project?.constructed_at &&
                    format(parseISO(project?.constructed_at), t("date_format"))}
                </MDTypography>
                <MDTypography variant="h6">
                  {t("Drawing Count")} {project?.drawings_count}
                </MDTypography>
                <MDTypography variant="h6">
                  {t("Normal Order")} {project?.normals_total}
                </MDTypography>
                <MDTypography variant="h6">
                  {t("Additional Order")} {project?.additions_total}
                </MDTypography>
                {isAllowed({ object: "Drawing", operator: "create", user, authenticated }) && (
                  <>
                    <MDButton
                      color="primary"
                      href={`${prefix}/projects/${projectId}/drawings/new`}
                      startIcon={<AddIcon />}
                    >
                      {t("New Drawing")}
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      href={`${prefix}/projects/${projectId}/bulkCreate`}
                      startIcon={<BurstModeIcon />}
                      sx={{ ml: 2 }}
                    >
                      {t(`Bulk Create Drawings`)}
                    </MDButton>
                  </>
                )}
              </MDBox>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <MDBox p={3}>
              <Grid container>
                <Grid item md={4}>
                  <MDTypography variant="h6">{t("Design")}</MDTypography>
                </Grid>
                <Grid item md={4}>
                  <MDTypography variant="h6">{t("Manufacturing")}</MDTypography>
                </Grid>
                <Grid item md={4}>
                  <MDTypography variant="h6">{t("Construction")}</MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Drawings Index")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                manualFilters
                manualGlobalFilter
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
                canSearch
                initialFilters={[{ id: "is_additional", value: 2 }]}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
