import PropTypes from "prop-types";
import { Chip, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import MDTypography from "../../MDTypography";

function PieceStat({ drawing: { piece_total: total }, stat, stage, merge }) {
  const { i18n } = useTranslation();
  if (merge && stat === total && total > 0) {
    return <Chip label={stage[`title_${i18n.language}`] || stage.title_en} color="primary" />;
  }
  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: "center",
        p: 2,
      }}
    >
      {stage[`title_${i18n.language}`] || stage.title_en}
      <Divider sx={{ m: 0 }} />
      <MDTypography variant="h4" sx={{ mb: 0 }}>
        {stat}/{total}
      </MDTypography>
    </Paper>
  );
}
PieceStat.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  merge: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  stage: PropTypes.object.isRequired,
  stat: PropTypes.number.isRequired,
};
PieceStat.defaultProps = {
  merge: false,
};

export default PieceStat;
