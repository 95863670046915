import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import QrReader from "react-qr-scanner";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import MDButton from "../../../components/MDButton";

function QrScanner({ onClose, isOpen }) {
  const { t } = useTranslation();
  const [text, setText] = useState();
  const [errorText, setError] = useState();
  // const [facing, setFacing] = useState("environment");
  const [loading, setLoading] = useState(false);
  const [cameraId, setCameraId] = useState();
  const [devices, setDevices] = useState([]);
  useEffect(async () => {
    setLoading(true);
    navigator.mediaDevices
      .enumerateDevices()
      .then((cams) => cams.filter(({ kind }) => kind === "videoinput"))
      .then((result) => {
        setCameraId(result[0]?.deviceId);
        setDevices(result);
        setLoading(false);
      })
      .catch((error) => setError(error));
  }, []);
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogContent>
        <div style={{ maxWidth: "400px", margin: "auto" }}>
          {devices?.length && (
            <FormControl>
              <InputLabel id="qrscanner">{t("Pick Camera")}</InputLabel>
              <Select
                labelId="qrscanner"
                id="langSel"
                value={cameraId}
                onChange={({ target: { value } }) => setCameraId(value)}
              >
                {devices.map(({ deviceId, label }, index) => (
                  <MenuItem value={deviceId} key={deviceId}>
                    {label || `camera ${index}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {/* <select onChange={(e) => setFacing(e.target.value)}> */}
          {/*  <option value="environment">Back Camera</option> */}
          {/*  <option value="user">Front Camera</option> */}
          {/*  <option value="left">Left Camera</option> */}
          {/*  <option value="right">Right Camera</option> */}
          {/* </select> */}
          {!loading && (
            <QrReader
              onError={(error) => setError(error)}
              constraints={
                cameraId !== undefined && {
                  audio: false,
                  video: { deviceId: cameraId },
                  // facingMode: { exact: facing },
                }
              }
              delay={100}
              style={{
                height: 240,
                width: 320,
              }}
              onScan={(result) => {
                if (result?.text) {
                  setText(result.text);
                }
              }}
            />
          )}
          <p>
            {t("Scanned: ")} {text && <a href={text}>{text}</a>}
          </p>
          {errorText && (
            <Alert variant="outlined" severity="error">
              {errorText}
            </Alert>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "gray" }}>
        <MDButton color="primary" onClick={onClose} startIcon={<CloseIcon />}>
          {t("Close Camera")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

QrScanner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default QrScanner;
