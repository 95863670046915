import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { useSanctum } from "react-sanctum";
import { Chip, CircularProgress, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import useFetch from "../../../utils/hooks/useFetch";
import StageTypeServices from "../../../services/StageTypeServices";
import PieceProgressList from "../../Piece/Progresses";
import { isAllowed, PROGRESSES } from "../../../utils/config";

function Modal({ drawing }) {
  const { t } = useTranslation();
  const [stageTypes] = useFetch({
    fetchFn: async () => StageTypeServices.index(),
    deps: [drawing.id],
    initialState: [],
  });
  const { user, authenticated } = useSanctum();
  return (
    <DialogContent sx={{ p: 6 }}>
      {stageTypes.length === 0 && <CircularProgress size={20} mb={3} />}
      {stageTypes.map((type) => (
        <MDBox my={2} key={type.id}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isAllowed({
              object: PROGRESSES[type.name].permObject,
              operator: "viewAny",
              user,
              authenticated,
            }) ? (
              <PieceProgressList drawing={drawing} type={type.name} title={t(type.name)} />
            ) : (
              <MDTypography variant="h6">{t(type.name)}</MDTypography>
            )}
            {drawing.latest_revision[PROGRESSES[type.name].count] === drawing.piece_total ? (
              <Chip label={t("Completed")} color="success" variant="filled" />
            ) : (
              <Chip label={t("Uncompleted")} color="error" variant="outlined" />
            )}
          </Paper>
        </MDBox>
      ))}
    </DialogContent>
  );
}
Modal.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  drawing: PropTypes.object.isRequired,
};

export default Modal;
