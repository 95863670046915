import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Trans, useTranslation } from "react-i18next";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMeasure } from "react-use";
import MDButton from "../MDButton";
import MDBox from "../MDBox";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PdfViewer({ src }) {
  const { t } = useTranslation();
  const [numOfPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => setPageNumber((prevPageNumber) => prevPageNumber + offset);

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  // const onItemClick = ({ pageNumber: itemPageNumber }) => setPageNumber(itemPageNumber);
  const [ref, { width }] = useMeasure();
  return (
    <MDBox ref={ref} display="flex" flexDirection="column">
      <MDButton href={src} download color="info" target="_blank" startIcon={<PictureAsPdfIcon />}>
        {t("Download")}
      </MDButton>
      <div style={{ overflow: "auto", width: `${width}px` }}>
        <Document
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
            standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
          }}
          loading={t("Loading PDF…")}
          error={t("Failed to load PDF file.")}
          noData={t("No PDF file specified.")}
        >
          {numOfPages && (
            <Page
              width={800}
              pageNumber={pageNumber}
              error={t("Failed to load the page.")}
              loading={t("Loading page…")}
              noData={t("No page specified.")}
            />
          )}
        </Document>
      </div>

      {numOfPages > 1 && (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDButton color="info" onClick={previousPage} sx={{ ml: 2 }} disabled={pageNumber <= 1}>
            {t("Prev")}
          </MDButton>
          <p>
            <Trans i18nKey="pdf_pagination_info">
              Page {{ pageNumber }} of {{ numOfPages }}
            </Trans>
          </p>
          <MDButton
            color="info"
            onClick={nextPage}
            sx={{ ml: 2 }}
            disabled={pageNumber >= numOfPages}
          >
            {t("Next")}
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

PdfViewer.propTypes = {
  src: PropTypes.string.isRequired,
};
export default PdfViewer;
