import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { localeMap } from "../utils/config";

// eslint-disable-next-line react/prop-types
export default function MyLocalizationProvider({ children }) {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[language || "en"]}>
      {children}
    </LocalizationProvider>
  );
}
