/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { TextField, Switch } from "formik-mui";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useSanctum } from "react-sanctum";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PropTypes from "prop-types";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import isEmailValidator from "validator/lib/isEmail";
import { processError } from "../../../utils/services/api";

function SignIn({ defaultPath }) {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t("Email is required"))
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
    password: yup.string().required(t("Password is required")).min(8),
    rememberMe: yup.boolean(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || defaultPath;

  const { signIn, authenticated } = useSanctum();

  const handleLogin = async ({ email, password, rememberMe }, { setSubmitting }) => {
    try {
      await signIn(email, password, rememberMe);
      toast.success(t("Successfully logged in"));
      navigate(from, { replace: true });
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  if (authenticated) {
    return <Navigate to={from} replace />;
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("Log in")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberMe: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({ values: { rememberMe }, isValid, submitForm, isSubmitting, setFieldValue }) => (
              <Form>
                <MDBox mb={4}>
                  <Field
                    component={TextField}
                    name="email"
                    autoComplete="email"
                    type="text"
                    label={t("Email")}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={4}>
                  <Field
                    component={TextField}
                    name="password"
                    autoComplete="current-password"
                    type="password"
                    label={t("Password")}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Field component={Switch} name="rememberMe" type="checkbox" />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={() => setFieldValue("rememberMe", !rememberMe)}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    {t("Remember Me")}
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  {isSubmitting && <CircularProgress />}
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                  >
                    {t("Log in")}
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography
            variant="button"
            component={Link}
            to="/forgot-pw"
            color="info"
            fontWeight="medium"
            textGradient
          >
            {t("Forget Password?")}
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

SignIn.propTypes = {
  defaultPath: PropTypes.string,
};
SignIn.defaultProps = {
  defaultPath: "/projects",
};
export default SignIn;
