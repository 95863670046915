import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import isEmailValidator from "validator/lib/isEmail";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import EmployeeServices from "../../../../services/EmployeeServices";
import { processError } from "../../../../utils/services/api";
import useFetch from "../../../../utils/hooks/useFetch";
import ClientServices from "../../../../services/ClientServices";

export default function EmployeeSingleEdit() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const validationSchema = yup.object({
    name: yup.string().max(30),
    division: yup.string().max(20),
    phone: yup.string().max(220),
    mail: yup
      .string()
      .max(120)
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
    client_id: yup.number().positive().required(),
  });
  const { id } = useParams();
  const [employee, setEmployee] = useFetch({
    fetchFn: async () => EmployeeServices.detail({ id }),
    deps: [id],
  });
  const [clients] = useFetch({
    initialState: [],
    fetchFn: async () => ClientServices.index(),
  });
  const handleEdit = async (
    // eslint-disable-next-line camelcase
    payload,
    { setSubmitting }
  ) => {
    try {
      const {
        data: { data: fetchedEmployee },
      } = await EmployeeServices.update({
        id,
        ...payload,
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setEmployee(fetchedEmployee);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Employees Edit")}
              </MDTypography>
            </MDBox>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: employee.name || "",
                    division: employee.division || "",
                    phone: employee.phone || "",
                    mail: employee.mail || "",
                    client_id: clients.length > 0 ? employee.client_id || "" : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleEdit}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label={t("Employee Name")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="division"
                          type="text"
                          label={t("Division")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label={t("Phone")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="mail"
                          type="text"
                          label={t("Email")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            id="client_id"
                            name="client_id"
                            select
                            variant="standard"
                            label={t("Client")}
                            required
                          >
                            {clients.map(({ name, id: clientId }) => (
                              <MenuItem value={clientId} key={clientId}>
                                {name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
