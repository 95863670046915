import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index(params) {
    return authClient.get("/api/stages", { params });
  },
  async create(payload) {
    return authClient.post(`/api/stages`, payload);
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/stages/${id}`, payload);
  },
  async destroy({ id }) {
    return authClient.delete(`/api/stages/${id}`);
  },
  async detail({ id }) {
    return authClient.get(`/api/stages/${id}`);
  },
};
