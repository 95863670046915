import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode";
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  View,
  Text,
  Image,
  Font,
} from "@react-pdf/renderer";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import notoSansRegular from "assets/fonts/NotoSansCJKjp-Regular.ttf";
import MDButton from "../../MDButton";
import { appUrl } from "../../../utils/config";

Font.register({
  family: "Noto",
  src: notoSansRegular,
  format: "truetype",
  // fonts: [
  //   // { src: notoSansB, fontWeight: 600 },
  //   // { src: '../../../fonts/static/Inter-Light.ttf', fontWeight: 300 },
  //   { src: notoSansSemiB, fontWeight: 500 },
  //   { src: notoSansRegular, fontWeight: 400 },
  // ],
});
/* eslint-disable camelcase */

// eslint-disable-next-line no-unused-vars
function Pdf({ drawing, gridSize, amount, baseGrid, isCommentable }) {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: "2mm",
      flexWrap: "wrap",
      flexDirection: "row",
      display: "flex",
      backgroundColor: "#E4E4E4",
    },
    cell: {
      width: `${(gridSize * 100) / baseGrid}%`,
      padding: "2mm",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    qrCodeWrapper: {
      border: 1,
      width: isCommentable ? "40%" : "100%",
    },
    qrText: {
      paddingLeft: 5,
      width: "60%",
    },
    bottomLine: {
      borderBottom: 1,
    },
    drawingTitle: {
      fontFamily: "Noto",
      textAlign: "center",
      fontSize: 12,
      marginTop: 6,
    },
    bottomFrame: {
      marginTop: 10,
      border: 1,
      height: 80,
    },
  });

  const [QRCodeString, setQRCodeString] = useState(false);
  useEffect(() => {
    QRCode.toDataURL(`${appUrl}viewer/drawings/${drawing.id}`).then((data) => {
      setQRCodeString(data);
    });
  }, [drawing.id]);
  const title = `${drawing.project.name}-${drawing.name}`;
  return (
    <PDFViewer width="100%" height="700px">
      <Document title={title} language="ja">
        <Page size="A4" style={styles.page} wrap={false}>
          {[...Array(amount)].map((i) => (
            <View key={i} style={styles.cell}>
              <View style={isCommentable ? styles.row : null}>
                <View style={styles.qrCodeWrapper}>
                  <Image source={{ uri: QRCodeString }} />
                </View>
                <View style={isCommentable ? styles.qrText : null}>
                  <Text style={styles.drawingTitle}>{title}</Text>
                  {isCommentable && (
                    <>
                      <View style={styles.bottomLine}>
                        <Text> </Text>
                      </View>
                      <View style={styles.bottomLine}>
                        <Text> </Text>
                      </View>
                      <View style={styles.bottomLine}>
                        <Text> </Text>
                      </View>
                    </>
                  )}
                </View>
              </View>
              {isCommentable && <View style={styles.bottomFrame} />}
            </View>
          ))}
        </Page>
      </Document>
    </PDFViewer>
  );
}

function QRPdfButton({ title, ...props }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MDButton color="info" onClick={handleOpen} sx={{ ml: 2 }}>
        {title}
      </MDButton>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="large"
            sx={{
              position: "absolute",
              right: -8,
              top: -8,
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Pdf {...props} />
        </DialogContent>
      </Dialog>
    </>
  );
}

Pdf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  gridSize: PropTypes.number,
  baseGrid: PropTypes.number,
  amount: PropTypes.number,
  isCommentable: PropTypes.bool,
};
Pdf.defaultProps = {
  gridSize: 3,
  baseGrid: 12,
  amount: 24,
  isCommentable: false,
};
QRPdfButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  gridSize: PropTypes.number,
  baseGrid: PropTypes.number,
  amount: PropTypes.number,
  isCommentable: PropTypes.bool,
};
QRPdfButton.defaultProps = {
  gridSize: 3,
  amount: 24,
  baseGrid: 12,
  isCommentable: false,
};

export default QRPdfButton;
