import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import isEmailValidator from "validator/lib/isEmail";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import PartnerServices from "../../../../services/PartnerServices";
import { processError } from "../../../../utils/services/api";
import useFetch from "../../../../utils/hooks/useFetch";
import { PARTNER_TYPES } from "../../../../utils/config";

export default function PartnerSingleEdit() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const validationSchema = yup.object({
    name: yup.string().max(30),
    address: yup.string().max(120),
    phone: yup.string().max(220),
    mail: yup
      .string()
      .max(120)
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
    type: yup.string().oneOf(PARTNER_TYPES),
  });
  const { id } = useParams();
  const [partner, setPartner] = useFetch({
    fetchFn: async () => PartnerServices.detail({ id }),
    deps: [id],
  });
  const handleEdit = async (
    // eslint-disable-next-line camelcase
    { name },
    { setSubmitting }
  ) => {
    try {
      const {
        data: { data: fetchedPartner },
      } = await PartnerServices.update({
        id,
        name,
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setPartner(fetchedPartner);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Partners Edit")}
              </MDTypography>
            </MDBox>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: partner.name || "",
                    address: partner.address || "",
                    phone: partner.phone || "",
                    mail: partner.mail || "",
                    type: partner.type || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleEdit}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label={t("Partner Name")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="address"
                          type="text"
                          label={t("Partner Address")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label={t("Partner Phone")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="mail"
                          type="text"
                          label={t("Partner Email")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            id="type"
                            name="type"
                            select
                            variant="standard"
                            label={t("Partner Type")}
                            required
                          >
                            {PARTNER_TYPES.map((item) => (
                              <MenuItem value={item} key={item}>
                                {t(`PartnerType-${item}`)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
