import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMountedState } from "react-use";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { useSanctum } from "react-sanctum";
import MDButton from "../../../components/MDButton";
import { processError } from "../../../utils/services/api";
import DeleteConfirmationDlg from "../DeleteConfirmationDlg";
import { isAllowed } from "../../../utils/config";

function ItemActions({ editLink, onDelete, object, question, editText }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const handleUserDelete = async () => {
    setLoading(true);
    try {
      await onDelete();
    } catch (error) {
      if (isMounted) {
        setLoading(false);
      }
      processError(error);
    }
  };

  const [open, setOpen] = useState(false);
  const handleDeleteClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user, authenticated } = useSanctum();
  return (
    <>
      {isAllowed({ object, operator: "update", user, authenticated }) && (
        <MDButton color="info" href={editLink} startIcon={<EditIcon />}>
          {editText || t("Edit")}
        </MDButton>
      )}
      {onDelete && isAllowed({ object, operator: "delete", user, authenticated }) && (
        <>
          <MDButton
            color="warning"
            onClick={handleDeleteClick}
            disabled={loading}
            sx={{ ml: 2 }}
            startIcon={loading ? <CircularProgress size={20} color="white" /> : <DeleteIcon />}
          >
            {t("Delete")}
          </MDButton>
          <DeleteConfirmationDlg
            question={question}
            open={open && !loading}
            onClose={handleClose}
            onOk={handleUserDelete}
          />
        </>
      )}
    </>
  );
}
ItemActions.propTypes = {
  editLink: PropTypes.string.isRequired,
  editText: PropTypes.string,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  object: PropTypes.string.isRequired,
};
ItemActions.defaultProps = {
  question: null,
  onDelete: false,
  editText: null,
};

export default ItemActions;
