import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { langChangedHandle } from "../../i18n";

export default function LangSelector() {
  const { t, i18n } = useTranslation();

  const changeLanguage = async ({ target: { value } }) => {
    await i18n.changeLanguage(value, langChangedHandle);
  };
  const countries = [
    {
      lang: "ja",
      country: "JP",
      text: "日本語",
    },
    {
      lang: "en",
      country: "GB",
      text: "English",
    },
    {
      lang: "vi",
      country: "VN",
      text: "Tiếng Việt",
    },
  ];
  return (
    <FormControl>
      <InputLabel id="lange-sel-lbl">{t("Language")}</InputLabel>
      <Select labelId="lang-sel-lbl" id="langSel" value={i18n.language} onChange={changeLanguage}>
        {countries.map(({ lang, text, country }) => (
          <MenuItem value={lang} key={lang}>
            <MDBox display="flex" alignItems="center">
              <ReactCountryFlag
                countryCode={country}
                style={{
                  fontSize: "2em",
                  lineHeight: "1em",
                }}
              />
              <MDTypography ml={2} fontSize="medium">
                {text}
              </MDTypography>
            </MDBox>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
