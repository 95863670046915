/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import isEmailValidator from "validator/lib/isEmail";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import { useSanctum } from "react-sanctum";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DivisionServices from "../../services/DivisionServices";
import useFetch from "../../utils/hooks/useFetch";
import { processError } from "../../utils/services/api";
import MDButton from "../../components/MDButton";
import ProfileServices from "../../services/ProfileServices";

function Profile() {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .max(50)
      .required(t("Email is required"))
      .test(
        "is-valid",
        (message) => t(`${message.path} is invalid`),
        (value) => (value ? isEmailValidator(value) : new yup.ValidationError(t("Invalid value")))
      ),
    phone: yup.string().max(50),
    name: yup.string().max(30),
    division_id: yup.number().positive(),
  });
  const validationPwSchema = yup.object({
    password: yup.string().required(t("Password is required")).min(8),
    password_confirmation: yup
      .string()
      .required(t("Password confirmation is required"))
      .oneOf([yup.ref("password"), null], t("Passwords must match"))
      .min(8),
    current_password: yup.string().required(t("Current Password is required")).min(8),
  });
  const { id } = useParams();
  const { user, signOut } = useSanctum();
  const [divisions] = useFetch({
    initialState: [],
    fetchFn: async () => DivisionServices.index(),
  });
  const handleEdit = async (
    // eslint-disable-next-line camelcase
    payload,
    { setSubmitting }
  ) => {
    try {
      if (payload.password) {
        await ProfileServices.updatePassword({
          id,
          ...payload,
        });
        await signOut();
      } else {
        await ProfileServices.update({
          id,
          ...payload,
        });
      }
      toast.success(t("Succeeded"));
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <>
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" color="white">
                {t("Users Change Password")}
              </MDTypography>
              <MDBox p={2} flexGrow={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {t("Change Password")}
                </MDTypography>
                <Formik
                  enableReinitialize
                  initialValues={{
                    password: "",
                    password_confirmation: "",
                    current_password: "",
                  }}
                  validationSchema={validationPwSchema}
                  onSubmit={handleEdit}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="current_password"
                          type="password"
                          label={t("Current Password")}
                          variant="standard"
                          autoComplete="password"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          label={t("New Password")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="password_confirmation"
                          required
                          type="password"
                          autoComplete="new-password"
                          label={t("New Password Confirmation")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MDBox p={2} flexGrow={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {t("Profile Edit")}
                </MDTypography>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: user.email || "",
                    phone: user.phone || "",
                    name: user.name || "",
                    division_id: divisions.length > 0 ? user.division_id || "" : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleEdit}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label={t("Name")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="email"
                          type="text"
                          label={t("Email")}
                          variant="standard"
                          fullWidth
                          required
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label={t("Phone")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            id="division_id"
                            name="division_id"
                            select
                            variant="standard"
                            label={t("Division")}
                          >
                            {divisions.map(({ name, id: divisionId }) => (
                              <MenuItem value={divisionId} key={divisionId}>
                                {name}
                              </MenuItem>
                            ))}
                          </Field>
                          {t("Current Value:")} {user?.division?.name}
                        </FormControl>
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </>
  );
}

export default Profile;
