import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import MDButton from "../../MDButton";
import StepSettingsDialog from "./StepSettingsDialog";
/* eslint-disable camelcase */
function ProgressStackNoDefault({
  steps,
  templateSteps,
  drawing,
  stages,
  progresses,
  initialSteps,
  submitStage: { stage_id, step_settings = [], step_ids = [] },
  setVals,
}) {
  const { i18n } = useTranslation();
  const defaultId = stage_id || progresses[0]?.stage_id;

  const defaultSteps = useMemo(() => {
    if ((step_settings?.length || 0) === 0) {
      if ((initialSteps.length || 0) > 0) {
        return initialSteps.map(({ step_id }) => step_id);
      }
      if (drawing.is_bulk) {
        return steps.filter(({ id }) => templateSteps.includes(id)).map(({ id }) => id);
      }
      return steps.filter(({ is_default }) => is_default).map(({ id }) => id);
    }
    return step_settings;
  }, [
    JSON.stringify(step_settings),
    JSON.stringify(initialSteps),
    JSON.stringify(templateSteps),
    JSON.stringify(drawing),
  ]);
  const defaultStepIds =
    ((step_ids?.length || 0) > 0 ? step_ids : null) ||
    initialSteps.filter(({ is_active }) => is_active).map(({ step_id }) => step_id);
  const [selected, setSelected] = useState();
  const [stepSettings, setStepSettings] = useState([]);
  const [stepIds, setStepIds] = useState([]);
  const progressing = stages.find(({ name }) => name === "progressing");
  const defaultStage = stages.find(({ is_default }) => is_default);
  // console.log({
  //   stepIds,
  //   stepSettings,
  //   defaultStepIds,
  //   defaultSteps,
  //   step_settings,
  //   initialSteps,
  //   step_ids,
  // });
  useEffect(() => {
    setSelected(defaultId);
  }, [defaultId]);
  useEffect(() => {
    setStepSettings(defaultSteps);
    setVals({ col: "step_settings", newVal: defaultSteps });
    setStepIds(defaultStepIds);
    setVals({ col: "step_ids", newVal: defaultStepIds });
  }, [JSON.stringify({ defaultSteps, defaultStepIds })]);
  const handleStepChange = (id) => {
    if (!stepIds.includes(id)) {
      const newVal = [...stepIds, id];
      setStepIds(newVal);
      setVals({ col: "step_ids", newVal });
      if (stage_id !== stages[stages.length - 1].id) {
        setSelected(progressing.id);
        setVals({ col: "stage_id", newVal: progressing.id });
      }
    } else {
      const newVal = stepIds.filter((v) => v !== id);
      setStepIds(newVal);
      setVals({ col: "step_ids", newVal });
      if (newVal.length === 0) {
        setSelected(defaultStage.id);
        setVals({ col: "stage_id", newVal: defaultStage.id });
      } else if (newVal.length < steps.length) {
        setSelected(progressing.id);
        setVals({ col: "stage_id", newVal: progressing.id });
      }
    }
  };
  return (
    <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" spacing={2}>
      {steps
        .filter(
          ({ id, is_default }) => stepSettings?.includes(id) || (is_default && !drawing.is_bulk)
        )
        .map(({ id, ...step }) => (
          <MDButton
            variant={stepIds?.includes(id) ? "contained" : "outlined"}
            onClick={() => handleStepChange(id)}
            key={id}
            color="primary"
            sx={{ minWidth: "100px" }}
          >
            {step[`title_${i18n.language}`] || step.title_en}
          </MDButton>
        ))}
      {stages
        .filter(({ is_default, is_hidden }) => !(is_hidden || is_default))
        .map(({ name, id, ...stage }) => (
          <MDButton
            variant={selected === id ? "contained" : "outlined"}
            // todo reflect selected value into button status
            key={id}
            color="primary"
            disabled={stepIds.length !== stepSettings.length}
            onClick={() => {
              if (selected === id && id === stages[stages.length - 1].id) {
                // OFF value
                if (stepIds.length > 0) {
                  setSelected(progressing.id);
                  setVals({ col: "stage_id", newVal: progressing.id });
                } else {
                  setSelected(defaultStage.id);
                  setVals({ col: "stage_id", newVal: defaultStage.id });
                }
              } else {
                setSelected(id);
                setVals({ col: "stage_id", newVal: id });
              }
            }}
          >
            {stage[`title_${i18n.language}`] || stage.title_en}
          </MDButton>
        ))}
      <StepSettingsDialog
        drawing={drawing}
        steps={steps}
        // eslint-disable-next-line camelcase
        current={stepSettings}
        handleSubmit={(newSteps) => {
          setStepSettings(newSteps);
          setStepIds([]);
          setSelected(defaultStage.id);
          setVals({ col: "step_settings", newVal: newSteps });
          setVals({ col: "step_ids", newVal: [] });
          setVals({ col: "stage_id", newVal: defaultStage.id });
        }}
      />
    </Stack>
  );
}
/* eslint-disable react/forbid-prop-types */
ProgressStackNoDefault.propTypes = {
  progresses: PropTypes.array,
  stages: PropTypes.array,
  templateSteps: PropTypes.array,
  drawing: PropTypes.object,
  submitStage: PropTypes.object,
  steps: PropTypes.array,
  initialSteps: PropTypes.array,
  setVals: PropTypes.func,
};

ProgressStackNoDefault.defaultProps = {
  progresses: [],
  stages: [],
  templateSteps: [],
  drawing: {},
  steps: [],
  initialSteps: [],
  submitStage: {},
  setVals: () => {},
};

export default ProgressStackNoDefault;
