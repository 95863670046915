import { useTranslation } from "react-i18next";
import { useMountedState } from "react-use";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import DataTable from "../../../examples/Tables/DataTable";
import PieceServices from "../../../services/PieceServices";
import PieceInfoButton from "./PieceInfoButton";

function PieceList({ revisionId }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await PieceServices.indexByRev({
      revisionId,
      page: pageIndex + 1,
      pageSize,
      sortBy,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Piece Name"),
      accessor: "name",
      align: "left",
    },
    {
      /* eslint-disable react/no-unstable-nested-components,react/prop-types,camelcase */
      Header: t("Status"),
      accessor: ({ latest_progress }) => {
        const [progress] = latest_progress || [];
        const { stage } = progress || {};
        const { type } = stage || {};
        const { name } = type || {};
        return name;
      },
      align: "left",
      Cell: ({ value }) => t(value),
      disableSortBy: true,
    },
    {
      Header: t("Piece Detail"),
      accessor: "id",
      Cell: ({ row: { original } }) => <PieceInfoButton piece={original} />,
      /* eslint-enable react/no-unstable-nested-components,react/prop-types,camelcase */
      align: "left",
      disableSortBy: true,
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <DataTable
      initialSorts={[{ id: "name", desc: false }]}
      table={{ columns, rows }}
      manualPagination
      onFetchData={handleFetchData}
      pageCount={pageCount}
      skipPageReset={skipPageResetRef.current}
      loading={loading}
      total={total}
      incr={revisionId}
    />
  );
}

PieceList.propTypes = {
  revisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PieceList;
