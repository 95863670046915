import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import DrawingServices from "../../../services/DrawingServices";
import PiecesInfo from "../../revisions/components/PiecesInfo";

/* eslint-disable react/prop-types,react/no-unstable-nested-components,no-unused-vars,camelcase */
export default function ProjectDrawingForPm() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const { project_id: projectId } = useParams();
  const handleFetchData = async ({
    pageIndex = 1,
    pageSize = 50,
    sortBy,
    globalFilter,
    filters,
  } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const mappedFilters = filters.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value,
      }),
      {}
    );
    const {
      data: {
        data,
        meta: { last_page, total: list_total },
      },
    } = await DrawingServices.index({
      projectId,
      page: pageIndex + 1,
      pageSize,
      sortBy,
      globalFilter,
      ...mappedFilters,
    });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Drawing Name"),
      accessor: "name",
      align: "left",
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }) => <PiecesInfo drawingId={id} title={value} variant="text" color="primary" />,
    },
    { Header: t("Piece Total"), accessor: "piece_total", align: "right", disableSortBy: true },
    {
      Header: t("In-house Work"),
      id: "in_house_count",
      align: "right",
      disableSortBy: true,
      accessor: ({ latest_revision }) => {
        const { in_house_count } = latest_revision || {};
        return in_house_count;
      },
    },
    {
      Header: t("Shipping"),
      id: "shipping_count",
      accessor: ({ latest_revision }) => {
        const { shipping_count } = latest_revision || {};
        return shipping_count;
      },
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("Acceptance"),
      id: "acceptance_count",
      accessor: ({ latest_revision }) => {
        const { acceptance_count } = latest_revision || {};
        return acceptance_count;
      },
      align: "right",
      disableSortBy: true,
    },
    {
      Header: t("Coverage"),
      id: "coverage_count",
      accessor: ({ latest_revision }) => {
        const { coverage_count } = latest_revision || {};
        return coverage_count;
      },
      align: "right",
      disableSortBy: true,
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
            >
              <MDTypography variant="h6" color="white" mt={1}>
                {t("Project Progression Detail")}
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                table={{ columns, rows }}
                manualPagination
                manualFilters
                manualGlobalFilter
                onFetchData={handleFetchData}
                pageCount={pageCount}
                skipPageReset={skipPageResetRef.current}
                loading={loading}
                total={total}
                canSearch
                initialFilters={[{ id: "is_additional", value: 2 }]}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
