import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index({ pieceId, ...params }) {
    return authClient.get(`/api/pieces/${pieceId}/progresses`, { params });
  },
  async update({ id, ...payload }) {
    return authClient.put(`/api/progresses/${id}`, payload);
  },
  async detail({ id }) {
    return authClient.get(`/api/progresses/${id}`);
  },
};
