import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import ClientServices from "../../../../services/ClientServices";
import { processError } from "../../../../utils/services/api";

export default function ClientSingleNew() {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    name: yup.string().max(50),
    address: yup.string().max(120),
    phone: yup.string().max(220),
  });
  // const { navigate } = useNavigate();
  const handleCreate = async (
    // eslint-disable-next-line camelcase
    payload,
    { setSubmitting, resetForm }
  ) => {
    try {
      await ClientServices.create(payload);
      toast.success(t("Succeeded"));
      resetForm();
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Create Client")}
              </MDTypography>
            </MDBox>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6}>
                <Formik
                  initialValues={{
                    name: "",
                    address: "",
                    phone: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleCreate}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label={t("Name")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="address"
                          type="text"
                          label={t("Address")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label={t("Phone")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
