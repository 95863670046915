import axios from "../../utils/config/vendor/axios";

const authClient = axios;
export default {
  async index({ projectId, ...params }) {
    if (projectId) {
      return authClient.get(`/api/projects/${projectId}/drawings`, { params });
    }
    return authClient.get(`/api/drawings`, { params });
  },
  async create({ projectId, payload }) {
    return authClient.post(`/api/projects/${projectId}/drawings`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async bulkCreate({ projectId, payload }) {
    return authClient.post(`/api/projects/${projectId}/drawings/bulkCreate`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async update({ id, payload }) {
    payload.append("_method", "PUT");
    return authClient.post(`/api/drawings/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async destroy({ id }) {
    return authClient.delete(`/api/drawings/${id}`);
  },
  async reset({ id }) {
    return authClient.delete(`/api/drawings/${id}/reset`);
  },
  async detail({ id }) {
    return authClient.get(`/api/drawings/${id}`);
  },
};
