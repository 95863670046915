import AddIcon from "@mui/icons-material/Add";
import { useSanctum } from "react-sanctum";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";
import { isAllowed, toDashCase } from "../../utils/config";
import MDButton from "../../components/MDButton";

function NewAction({ object, prefix }) {
  const { t } = useTranslation();
  const { user, authenticated } = useSanctum();
  return isAllowed({ object, operator: "create", user, authenticated }) ? (
    <MDButton
      variant="gradient"
      color="primary"
      href={`${prefix}/${toDashCase(pluralize(object))}/new`}
      startIcon={<AddIcon />}
      sx={{ ml: "auto" }}
    >
      {t(`Create ${object}`)}
    </MDButton>
  ) : null;
}

NewAction.propTypes = {
  object: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};
NewAction.defaultProps = {
  prefix: "",
};

export default NewAction;
