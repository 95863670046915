import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function DoneFlag({ value, onClick }) {
  switch (value) {
    case true:
      return <CheckCircleOutlineIcon color="success" fontSize="large" onClick={onClick} />;
    case false:
      return <ArrowRightIcon color="error" fontSize="large" onClick={onClick} />;
    default:
      return null;
  }
}

DoneFlag.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DoneFlag;
