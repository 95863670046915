import PropTypes from "prop-types";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DialogContent from "@mui/material/DialogContent";
import PdfViewer from "../PdfViewer";

function Lightbox({ image }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (image.toLowerCase().includes(".pdf")) {
    return <PdfViewer src={image} />;
  }
  return (
    <>
      <img
        src={image}
        alt=""
        style={{ maxWidth: "100%", maxHeight: "400px" }}
        onClick={handleOpen}
        role="presentation"
      />
      <IconButton
        aria-label="zoom in"
        onClick={handleOpen}
        size="large"
        sx={{
          position: "absolute",
          right: 8,
          bottom: 8,
          backgroundColor: "#fff",
        }}
      >
        <ZoomInIcon />
      </IconButton>
      {open && (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              size="large"
              sx={{
                position: "absolute",
                right: -8,
                top: -8,
              }}
            >
              <CloseIcon />
            </IconButton>
            {!image.toLowerCase().includes(".pdf") && <img src={image} alt="" width="100%" />}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

Lightbox.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Lightbox;
