import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMountedState } from "react-use";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { format, parseISO } from "date-fns";
import useFetch, { fetchData } from "../../../../../utils/hooks/useFetch";
import RolePermissionServices from "../../../../../services/RolePermissionServices";
import DataTable from "../../../../../examples/Tables/DataTable";
import MDBox from "../../../../../components/MDBox";
import PermissionServices from "../../../../../services/PermissionServices";
import PermissionCheckbox from "./components/PermissionCheckbox";

function RolePermissions({ roleId }) {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const fetchRolePerms = async () =>
    RolePermissionServices.index({ role_id: roleId, pageSize: -1 });
  const [rolePermissions, setRolePerms] = useFetch({
    // eslint-disable-next-line camelcase
    fetchFn: fetchRolePerms,
    initialState: [],
  });

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState();
  const skipPageResetRef = useRef(false);
  const handleFetchData = async ({ pageIndex = 1, pageSize = 50, sortBy, filters } = {}) => {
    // Give this fetch an ID
    setLoading(true);
    const {
      data: {
        data,
        // eslint-disable-next-line no-unused-vars,camelcase
        meta: { last_page, total: list_total },
      },
    } = await PermissionServices.index({ page: pageIndex + 1, pageSize, sortBy, filters });
    if (!isMounted) return;
    setPageCount(last_page);
    setTotal(list_total);
    skipPageResetRef.current = true;
    setRows(
      // eslint-disable-next-line camelcase
      data.map(({ id, ...payload }) => ({
        ...payload,
        id,
        action: id,
      }))
    );
    setLoading(false);
  };

  const columns = [
    {
      Header: t("Check"),
      disableSortBy: true,
      accessor: "id",
      align: "right",
      // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
      Cell: ({ value }) => {
        const [disabled, setDisable] = useState(false);
        return (
          <PermissionCheckbox
            rolePermissions={rolePermissions}
            disabled={disabled}
            permissionId={value}
            roleId={roleId}
            afterChange={async () =>
              fetchData({
                fetchFn: fetchRolePerms,
                setData: setRolePerms,
                isMounted,
                setFetching: setDisable,
              })
            }
          />
        );
      },
    },
    {
      Header: t("Permission Object"),
      id: "permission_objects.name",
      accessor: ({ object: { name } = {} }) => name,
      align: "left",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { object_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/admin/permission-objects/${object_id}/edit`}>
          {t(value)}
        </Link>
      ),
    },
    {
      Header: t("Permission Operator"),
      accessor: ({ operator: { name } = {} }) => name,
      id: "permission_operators.name",
      align: "left",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { operator_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/admin/permission-operators/${operator_id}/edit`}>
          {t(value)}
        </Link>
      ),
    },
    {
      Header: t("Created By"),
      // eslint-disable-next-line camelcase
      accessor: ({ id: permissionId }) => {
        const { created_by: createdBy } =
          // eslint-disable-next-line camelcase
          rolePermissions.find(({ permission_id }) => permission_id === permissionId) || {};
        // eslint-disable-next-line camelcase
        const { name } = createdBy || {};
        return name;
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        // eslint-disable-next-line react/prop-types
        value,
        // eslint-disable-next-line react/prop-types
        row: {
          // eslint-disable-next-line react/prop-types,camelcase
          original: { creator_id },
        },
      }) => (
        // eslint-disable-next-line camelcase
        <Link component={RouterLink} to={`/admin/users/${creator_id}/edit`}>
          {value}
        </Link>
      ),
      align: "left",
      disableSortBy: true,
    },
    {
      Header: t("Created At"),
      id: "created_at",
      sortDescFirst: true,
      // eslint-disable-next-line camelcase
      accessor: ({ created_at }) => format(parseISO(created_at), "yyy-MM-dd HH:mm:ss"),
      align: "left",
    },
  ];
  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  }, [rows]);
  return (
    <MDBox p={3}>
      <DataTable
        table={{ columns, rows }}
        manualPagination
        onFetchData={handleFetchData}
        pageCount={pageCount}
        skipPageReset={skipPageResetRef.current}
        loading={loading}
        total={total}
      />
    </MDBox>
  );
}

RolePermissions.propTypes = {
  roleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RolePermissions;
