/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Sanctum } from "react-sanctum";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import "./utils/config/vendor/axios";
import App from "App";
// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { sanctum } from "./utils/config";
// loading component for suspence fallback
function Loader() {
  return (
    <div className="App">
      <div>loading...</div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <MaterialUIControllerProvider>
        <Sanctum config={sanctum}>
          <App />
        </Sanctum>
      </MaterialUIControllerProvider>
    </Suspense>
  </BrowserRouter>
);
