import enLocale from "date-fns/locale/en-US";
import jaLocale from "date-fns/locale/ja";

export const localeMap = {
  en: enLocale,
  ja: jaLocale,
};
export const appUrl = process.env.REACT_APP_URL;
export const backend = process.env.REACT_APP_BACKEND;
export const sanctum = {
  apiUrl: backend,
  csrfCookieRoute: "sanctum/csrf-cookie",
  signInRoute: "login",
  signOutRoute: "logout",
  userObjectRoute: "api/user",
};
export const DEFAULT_LANG = "ja";
export const PARTNER_TYPES = ["design", "construction"];
export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("_");

export const toDashCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");

export function isAllowed({ object, operator, user, authenticated }) {
  const filtered = (user?.roles || []).filter(
    ({ active_permissions: perms }) =>
      !!perms.find(
        ({ object: { name: objName }, operator: { name: op } }) =>
          op === operator && objName === object
      )
  );
  return (!object && !operator) || (authenticated && filtered.length > 0);
}

export const SUPPORTED_IMG_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/svg+xml",
  "application/pdf",
];

export const revType = (isAdditional) => (isAdditional ? "Additional Order" : "Normal Order");
export const arrInsert = (arr, index, newItem) => [
  ...arr.slice(0, index),

  newItem,

  ...arr.slice(index),
];
export const PROGRESSES = {
  production_progress: {
    permObject: "ProductionProgress",
    relation: "productionProgress",
    relations: "productionProgresses",
    plural: "production_progresses",
    count: "production_count",
    path: "producings-stage",
    pathName: "Piece Producing Manager",
  },
  factory_inspection: {
    permObject: "FactoryInspection",
    relation: "factoryInspection",
    relations: "factoryInspections",
    plural: "factory_inspections",
    count: "factoring_count",
    path: "factoring-stage",
    pathName: "Piece Factory Inspection",
  },
  treatment: {
    permObject: "Treatment",
    relation: "treatment",
    relations: "treatments",
    plural: "treatments",
    count: "in_house_count",
    path: "treating-stage",
    pathName: "Piece Treatment Manager",
  },
  shipping_registration: {
    permObject: "ShippingRegistration",
    relation: "shipping",
    relations: "shippings",
    plural: "shippings",
    count: "shipping_count",
    path: "shipping-stage",
    pathName: "Piece Shipping Registration",
  },
  arrival_check: {
    permObject: "ArrivalCheck",
    relation: "arrivalCheck",
    relations: "arrivalChecks",
    plural: "arrival_checks",
    count: "arrived_count",
    path: "arriving-stage",
    pathName: "Piece Arrival Check",
  },
  site_inspection: {
    permObject: "SiteInspection",
    relation: "siteInspection",
    relations: "siteInspections",
    plural: "site_inspections",
    count: "acceptance_count",
    path: "site-stage",
    pathName: "Piece Site Inspection",
  },
  construction_progress: {
    permObject: "ConstructionProgress",
    relation: "constructionProgress",
    relations: "constructionProgresses",
    plural: "construction_progresses",
    count: "coverage_count",
    path: "constructing-stage",
    pathName: "Piece Construction Progress",
  },
};

export const matchRoute = (pathname, routesMap) =>
  (Object.entries(routesMap).find(
    ([, { prefix }]) => prefix.length > 0 && pathname.includes(prefix)
  ) || [undefined, undefined])[1] || routesMap.user;
