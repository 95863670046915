import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import PermissionServices from "../../../../services/PermissionServices";
import { processError } from "../../../../utils/services/api";
import useFetch from "../../../../utils/hooks/useFetch";

export default function PermissionSingleEdit() {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const validationSchema = yup.object({
    name: yup.string().max(30),
  });
  const { id } = useParams();
  const [permission, setPermission] = useFetch({
    fetchFn: async () => PermissionServices.detail({ id }),
    deps: [id],
  });
  const handleEdit = async (
    // eslint-disable-next-line camelcase
    { name },
    { setSubmitting }
  ) => {
    try {
      const {
        data: { data: fetchedPermission },
      } = await PermissionServices.update({
        id,
        name,
      });
      toast.success(t("Succeeded"));
      if (!isMounted) return;
      setPermission(fetchedPermission);
    } catch (error) {
      processError(error);
      setSubmitting(false);
    }
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              mb={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("Permissions Edit")}
              </MDTypography>
            </MDBox>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: permission.name || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleEdit}
                >
                  {({ isValid, submitForm, isSubmitting, resetForm }) => (
                    <Form>
                      <MDBox mb={4}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label={t("Name")}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ justifyContent: "space-between", display: "flex" }}
                      >
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={resetForm}
                        >
                          {t("Reset")}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          disabled={isSubmitting || !isValid}
                          onClick={submitForm}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
