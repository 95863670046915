import PropTypes from "prop-types";
import { Stack, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import MDButton from "../MDButton";
import theme from "../../assets/theme";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function TruncatedPopup({ text = "" }) {
  let truncLength = 20;
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  if (matches) {
    truncLength = 25;
  }
  if (text.length <= truncLength) {
    return text;
  }
  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} justifyContent="space-between">
      <div>{text.substring(0, truncLength)}...</div>
      <CustomWidthTooltip title={text} arrow placement="left">
        <MDButton variant="gradient" color="info">
          ...
        </MDButton>
      </CustomWidthTooltip>
    </Stack>
  );
}

TruncatedPopup.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TruncatedPopup;
