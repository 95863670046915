import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MyLocalizationProvider from "../../MyLocalizationProvider";

/* eslint-disable camelcase */
function PieceSetDatePicker({ set_at, setVals }) {
  const [currentSet, setCurrentSet] = useState();
  useEffect(() => {
    setCurrentSet(set_at);
  }, [set_at]);
  const handleChange = (newVal) => {
    setCurrentSet(newVal);
    setVals({ col: "set_at", newVal });
  };
  return (
    <MyLocalizationProvider>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        value={currentSet}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        sx={{ minWidth: "100px" }}
      />
    </MyLocalizationProvider>
  );
}
PieceSetDatePicker.propTypes = {
  set_at: PropTypes.instanceOf(Date).isRequired,
  setVals: PropTypes.func.isRequired,
};

export default PieceSetDatePicker;
