import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import MDButton from "../MDButton";

function SignInTemplate({ to, defaultPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <MDButton
      color="primary"
      onClick={() => navigate(to, { state: { from: { pathname: defaultPath || pathname } } })}
    >
      {t("Sign In")}
    </MDButton>
  );
}
SignInTemplate.propTypes = {
  to: PropTypes.string,
  defaultPath: PropTypes.string,
};
SignInTemplate.defaultProps = {
  to: "/sign-in",
  defaultPath: null,
};
export default SignInTemplate;
