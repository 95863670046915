import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Paper, Stack } from "@mui/material";
import MDButton from "../../MDButton";

// eslint-disable-next-line camelcase
function StepSettingsDialog({ handleSubmit, steps, current, drawing: { is_bulk = false } }) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [leftSide, setLeftSide] = useState([]);
  const [rightSide, setRightSide] = useState([]);
  useEffect(() => {
    setLeftSide(current);
  }, [current]);
  useEffect(() => {
    setRightSide(steps.filter(({ id }) => !current?.includes(id))?.map(({ id }) => id));
  }, [current, JSON.stringify(steps)]);
  const [leftSelection, setLeft] = useState([]);
  const [rightSelection, setRight] = useState([]);
  // eslint-disable-next-line camelcase
  const blocked = is_bulk ? [] : steps.filter(({ is_default }) => is_default).map(({ id }) => id);
  const handleBtnClick = (side, id, handler) => {
    if (blocked.includes(id)) {
      return;
    }
    if (side.includes(id)) {
      const result = side.filter((v) => v !== id);
      handler(result);
    } else {
      handler([...side, id]);
    }
  };
  const handleMoveLeft = () => {
    setLeftSide([...leftSide, ...rightSelection]);
    setRightSide(rightSide.filter((id) => !rightSelection.includes(id)));
    setRight([]);
  };
  const handleMoveRight = () => {
    setRightSide([...rightSide, ...leftSelection]);
    setLeftSide(leftSide.filter((id) => !leftSelection.includes(id)));
    setLeft([]);
  };
  const onSubmit = () => {
    handleSubmit(leftSide);
    handleClose();
  };
  return (
    <>
      <IconButton size="large" color="info" onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={5}>
              <Paper sx={{ p: 1 }}>
                {steps
                  .filter(({ id }) => leftSide?.includes(id))
                  .map(({ id, ...step }) => (
                    <MDButton
                      variant={leftSelection?.includes(id) ? "contained" : "outlined"}
                      color="info"
                      sx={{ m: 1 }}
                      key={id}
                      onClick={() => handleBtnClick(leftSelection, id, setLeft)}
                    >
                      {step[`title_${i18n.language}`] || step.title_en}
                    </MDButton>
                  ))}
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <IconButton onClick={handleMoveLeft}>
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton onClick={handleMoveRight}>
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item md={5}>
              <Paper sx={{ p: 1 }}>
                {steps
                  .filter(({ id }) => rightSide?.includes(id))
                  .map(({ id, ...step }) => (
                    <MDButton
                      variant={rightSelection?.includes(id) ? "contained" : "outlined"}
                      color="warning"
                      sx={{ m: 1 }}
                      key={id}
                      onClick={() => handleBtnClick(rightSelection, id, setRight)}
                    >
                      {step[`title_${i18n.language}`] || step.title_en}
                    </MDButton>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <MDButton variant="gradient" color="info" onClick={onSubmit}>
            {t("Apply")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
/* eslint-disable react/forbid-prop-types */
StepSettingsDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  steps: PropTypes.array,
  drawing: PropTypes.object.isRequired,
  current: PropTypes.array,
};
StepSettingsDialog.defaultProps = {
  steps: [],
  current: [],
};

StepSettingsDialog.defaultProps = {};
export default StepSettingsDialog;
