import PropTypes from "prop-types";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";

function BusinessConfirmation({ text, code, target }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const regex = new RegExp(code);
  const validationSchema = yup.object({
    order_number: yup
      .string()
      .required(t("order number is required"))
      .matches(regex, { message: t("Must be same order number") }),
  });
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate(target);
  };
  // go to target
  return (
    <MDBox sx={{ display: "flex", justifyContent: "center" }}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <MDTypography onClick={handleOpen} color="primary" variant="p">
        {text}
      </MDTypography>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h4">{text}</MDTypography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Trans i18nKey="project_business_confirmation">
            Please enter the order number of project {{ project: text }} to prevent erroneous
            operation
          </Trans>
          <Formik
            enableReinitialize
            initialValues={{
              order_number: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {/* eslint-disable-next-line camelcase */}
            {({ isValid, submitForm, isSubmitting }) => (
              <Form>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  mt={1}
                  mb={2}
                  spacing={2}
                >
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="order_number"
                      type="text"
                      label={`${t("order_number")}: ${code}`}
                      variant="standard"
                      fullWidth
                    />
                  </FormControl>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                  >
                    {isSubmitting && <CircularProgress />}
                    {t("Complete Input")}
                  </MDButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}

BusinessConfirmation.propTypes = {
  text: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

export default BusinessConfirmation;
