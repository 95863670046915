import PropTypes from "prop-types";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import Dialog from "@mui/material/Dialog";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import MDButton from "../../MDButton";
import MDTypography from "../../MDTypography";
import ProgressContent from "./ProgressContent";

function PieceProgressList(props) {
  // eslint-disable-next-line camelcase
  const { title, drawing: { name = "", project: { order_code: orderCode } } = {} } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MDButton
        variant="text"
        color="primary"
        onClick={handleOpen}
        startIcon={<FolderIcon />}
        sx={{ textTransform: "none" }}
      >
        {title || `${orderCode}-${name}`}
      </MDButton>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <FolderOpenIcon sx={{ fontSize: 40 }} />
              <MDTypography variant="h4">{name}</MDTypography>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        {open && <ProgressContent {...props} />}
      </Dialog>
    </>
  );
}
PieceProgressList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawing: PropTypes.object.isRequired,
  title: PropTypes.string,
};
PieceProgressList.defaultProps = {
  title: null,
};

export default PieceProgressList;
